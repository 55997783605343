<template>
  <div v-loading="loading">
    <el-select :value="value" :clearable="clearable" :placeholder="label" @change="onChange">
      <el-option :label="$tc('annuled', 0)" value="Annuled">
      </el-option>
      <el-option :label="$tc('open')" value="Open">
      </el-option>
      <el-option :label="$tc('paid')" value="Paid">
      </el-option>
    </el-select>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import select, { model } from './common'

export default {
  extends: select('', 'status'),
  props: {
    clearable: { required: false, default: false, type: Boolean },
  },
  data() {
    return model()
  },
  methods: {
    onChange(data) {
      this.$emit('input', data)
      this.$emit('change', data)
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
}
</script>
