<template>
  <el-select
    :value="value"
    :clearable="clearable"
    :placeholder="label"
    :remote-method="onLocalSearch"
    filterable
    remote
    reserve-keyword
    @change="onChange"
  >
    <el-option v-for="(item, index) in data" :key="index" :label="item.name" :value="item.code">
      <span style="float: left">{{ item.name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.code }}</span>
    </el-option>
  </el-select>
</template>
<script>

/* app
-------------------------------------------------- */
import select, { model } from './common'
import data from '@/assets/js/data/countries'

/* End of app
-------------------------------------------------- */
export default {
  extends: select('', 'country', { attributeLabel: 'name', attributeValue: 'code', data }),
  data() {
    return model()
  },
  watch: {
    value(newValue) {
      this.setLocalInitValue(newValue)
    },
  },
  mounted() {
    this.setLocalInitValue(this.value)
  },
}
</script>
