<template>
  <el-select :value="value" :placeholder="$tc('paymentMethod')" @change="onChange">
    <el-option value="TarjetaCredito" label="Tarjeta de crédito">
      Tarjeta de crédito
    </el-option>
    <el-option value="Pse" label="Pse">
      PSE
    </el-option>
    <el-option value="Consignacion" label="Consignacion">
      Consignacion
    </el-option>
    <el-option value="Transferencia" label="Transferencia">
      Transferencia
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: { required: true },
  },
  mounted() {
    // this.$emit('input', 'Consignacion')
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
    },
  },
}
</script>
