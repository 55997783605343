import Vue from 'vue'
import Vuex from 'vuex'
import { isLoggedIn } from '../assets/js/auth'

Vue.use(Vuex)

const state = {
  isLogged: isLoggedIn(),
  setting: JSON.parse(localStorage.getItem('masters')),
  plansByService: [],
}

const mutations = {
  setLogin(state) {
    state.isLogged = true
  },
  setLogout(state) {
    state.isLogged = false
  },
  setSetting(state, value) {
    state.setting = value
  },
  setPlansByService(state, value) {
    state.plansByService = value
  },
}

const getters = {
  setting: state => state.setting,
  isLogged: state => state.isLogged,
  plansByService: state => state.planByService,
}

const actions = {
  plansByService({ commit }, plans) {
    commit('setPlansByService', plans)
  },
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  actions,
  state,
  getters,
  mutations,
})
