<template>
  <el-select placeholder="..." :value="value" clearable @change="onChange">
    <el-option label="Activa" value="activa"></el-option>
    <el-option label="Lectura" value="lectura"></el-option>
    <el-option label="Suspendida" value="suspendida"></el-option>
    <el-option label="Cancelada" value="cancelada"></el-option>
    <el-option label="Prueba" value="prueba"></el-option>
    <el-option label="Demo" value="demo"></el-option>
    <el-option label="Maestra" value="maestra"></el-option>
    <el-option label="Interna" value="interna"></el-option>
    <el-option label="Desarrollo" value="desarrollo"></el-option>
    <el-option label="PruebaFinalizada" value="pruebaFinalizada"></el-option>
  </el-select>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    onChange(e) {
      this.$emit('input', e)
    },
  },
}
</script>
