<template>
  <el-autocomplete
    v-model="model.search"
    :fetch-suggestions="querySearch"
    :placeholder="$tc('typeForSearch')"
    value-key="name"
    :debounce="500"
    @select="onChange"
  >
  <template #default="scope">
    <el-tooltip placement="top">
      <div slot="content">
        {{ scope.item.name }}
      </div>
      <div>
      <i class="fad fa-building"></i>
        ({{ scope.item.code }}) {{ scope.item.name }}
      </div>
    </el-tooltip>
  </template>
</el-autocomplete>
</template>
<script>
import data from '@/assets/js/data/CO/institutions'
import select, { model } from './common'

export default {
  extends: select('', 'institution', {
    attributeLabel: 'name',
    attributeValue: 'code',
    data
  }),
  data() {
    return {
      model: model()
    }
  },
  mounted() {
    this.setLocalInitValue(this.value)
  },
  methods: {
    querySearch(queryString, cb) {
      const itemOthers = data.find(item => item.code === '9999')
      const results = queryString && data && data.length > 0 ? data.filter(
        item => item.name.toLowerCase().includes(queryString.toLowerCase())
        || item.code.toLowerCase().includes(queryString.toLowerCase())
      ) : []
      results.push(itemOthers)
      cb(results.filter((item, pos, self) => self.indexOf(item) === pos))
    },
    onChange(event) {
      const _this = this
      if (event && data && data.length > 0) {
        _this.$emit('set-code', event.code)
        _this.$emit('set-name', event.name)
        _this.$emit('input', event.code)
      } else {
        _this.$emit('set-code', '')
        _this.$emit('set-name', '')
        _this.$emit('input', '')
      }
    }
  }
}
</script>
