import Vue from 'vue'
import VueGtag from 'vue-gtag'

import Router from 'vue-router'

import { i18n } from '@/i18n'

import { requireAuthorization } from '../assets/js/auth'

Vue.use(Router)

/**
 * allowedRoles: ['roleId']   '1': NBXR Root,
 * '2': NBXR Soporte,
 * '3': NBXR Comercial,
 * '4': NBXR 4, 5: NBXR 5
 */
const routes = [
  /**
   *
   * Register sin modal
   *
   */
  {
    path: '/signup/site',
    name: 'RegisterCompanies',
    component: () => import('@/views/plan/register-companies'),
    meta: {
      layout: 'signup-blank',
      requiresAuth: false,
    },
  },
  {
    path: '/signup/students',
    name: 'RegisterStudents',
    component: () => import('@/views/plan/register-students'),
    meta: {
      layout: 'signup-blank',
      requiresAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/layouts/signup-layout'),
    meta: {
      layout: 'signup',
      requiresAuth: false,
    },
    children: [
      {
        /**
                    Cuando viene desde el CRM o desde el Sitio aliaddo.com
                    - debe venir el ?product query param, de la siguiente forma  FE - INICIAL -
                */
        path: '',
        name: 'SignupPersonalizeByProduct',
        component: () => import('@/views/plan/personalize'),
        meta: {
          requiresAuth: false,
        },
      },

      /**
                  CRM:
                  - Permite seleccionar plan
                  - Valida la tarifa seleccionada
              */
      {
        path: 'select',
        name: 'PlanSelectCreateAccountRegister',
        component: () => import('@/views/plan/select'),
        meta: {
          requiresAuth: false,
        },
      },

      /**
                  Sitio:
                  - Envia el nombre del plan actual seleccionado
              */
      {
        path: 'select/:planName',
        name: 'PlanSelectCreateAccountRegisterCurrent',
        component: () => import('@/views/plan/select'),
        meta: {
          requiresAuth: false,
        },
      },

      /**
                  Sitio:
                  - Envia el nombre del plan actual seleccionado y no se debe registrar ya tiene empresa
              */
      {
        path: 'select/:companyId/:planName',
        name: 'PlanSelectCreateAccountRegisterCurrentCompany',
        component: () => import('@/views/plan/select'),
        meta: {
          requiresAuth: false,
        },
      },

      /**
                  Sitio:
                  - Compra de paquetes adicionales
              */
      {
        path: 'select/:companyId/:planName/:detailType',
        name: 'PlanSelectPurchaseAdditional',
        component: () => import('@/views/plan/select'),
        meta: {
          requiresAuth: false,
        },
      },

      /* Cuando realice la apertura en los planes de nomina
    -------------------------------------------------- */
      {
        path: 'kick-off/nomina-electronica',
        name: 'KickOffNominaElectronica',
        component: () => import('@/views/kick-off/nomina-electronica'),
        meta: {
          requiresAuth: false,
        },
      },

      /* End of Cuando realice la apertura en los planes de nomina
    -------------------------------------------------- */
    ],
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/layouts/public-header-layout'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        /* ----------  Pagar el primer voucher cuando viene desde el CRM y son redireccionados desde el ERP , FE, ISV  ---------- */
        path: 'voucher/:service/:companyId',
        name: 'PaymentVoucherByCompany',
        component: () => import('@/views/voucher/payment'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        /* ----------  Pagar vouchers que se envian por email  ---------- */
        path: 'placeToPay/:placeToPayReferenceId/:companyId/:serviceId/payment/voucher',
        name: 'PlaceToPayPayment',
        component: () => import('@/views/voucher/payment'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/blank',
    name: 'Blank',
    component: () => import('@/layouts/blank-layout'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        /* ----------  Mostrar paquetes en iframe en isv  ---------- */
        path: 'companyServiceFeatures/:serviceId/:companyId',
        name: 'BlankCompanyServiceFeature',
        component: () => import('@/views/company-service-feature/public'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        /* ----------  Mostrar planes iframe para seleccionar sin pagar, lanza evento  ---------- */
        path: 'plans/select/:serviceId/:companyId/:mode',
        name: 'BlankPlansSelect',
        component: () => import('@/views/plan/select-without-payment'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    // Solo para el ERP mientras se actualiza
    // https://hub.aliaddo.com/plans/select/aliaddo/true/false/379e3948-6567-45eb-99fb-0315887a48cd/111?source=crm&1599889027162
    path: '/plans/select/:service/:isEmbedded/:isPacked/:companyId/:planId',
    name: 'PlanSelect',
    component: () => import('@/views/voucher/payment'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/add-ons',
    name: 'AddOnsIndex',
    component: () => import('@/views/add_on'),
    meta: {
      title: i18n.tc('addOn', 2),
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/add-ons/create',
    name: 'AddOnsCreate',
    component: () => import('@/views/add_on/create'),
    meta: {
      title: '',
      layout: 'private',
      requiresAuth: false,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/add-ons/update/:id',
    name: 'AddOnsUpdate',
    component: () => import('@/views/add_on/update'),
    meta: {
      title: '',
      layout: 'private',
      requiresAuth: false,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    beforeEnter: requireAuthorization,
    meta: {
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/support',
    name: 'HomeSupport',
    component: () => import('@/views/home-support'),
    beforeEnter: requireAuthorization,
    meta: {
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2, 3],
    },
  },
  {
    path: '/sellers',
    name: 'HomeSellers',
    component: () => import('@/views/home-sellers'),
    beforeEnter: requireAuthorization,
    meta: {
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2, 4],
    },
  },
  {
    path: '/companies',
    name: 'CompanyIndex',
    component: () => import('@/views/company/company-index'),
    beforeEnter: requireAuthorization,
    meta: {
      title: 'lookUp',
      allowedRoles: [1, 2, 3, 4],
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/companies/:id/update',
    name: 'CompanyUpdate',
    component: () => import('@/views/company/company-update'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1],
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/companies/create',
    name: 'CompanyCreate',
    component: () => import('@/views/company/company-create'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1],
      title: 'create',
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/companies/:id/logo',
    name: 'CompanyUpdateLogo',
    component: () => import('@/views/company/company-update-logo'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1, 2, 3],
      title: 'logo',
      requiresAuth: true,
    },
  },
  {
    path: '/plans',
    name: 'PlanIndex',
    beforeEnter: requireAuthorization,
    component: () => import('@/views/plan/index'),
    meta: {
      allowedRoles: [1, 2, 3],
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/plans/create',
    name: 'PlanCreate',
    component: () => import('@/views/plan/create'),
    meta: {
      allowedRoles: [1],
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/plans/:id/update',
    name: 'PlanUpdate',
    component: () => import('@/views/plan/update'),
    meta: {
      allowedRoles: [1],
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/features',
    name: 'FeatureIndex',
    component: () => import('@/views/feature/index'),
    beforeEnter: requireAuthorization,
    meta: {
      title: 'lookUp',
      allowedRoles: [1],
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/features/create',
    name: 'FeatureCreate',
    component: () => import('@/views/feature/create'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1],
      title: 'create',
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/features/:id/update',
    name: 'FeatureUpdate',
    component: () => import('@/views/feature/update'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1],
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/tenants',
    name: 'TenantIndex',
    component: () => import('@/views/tenant/tenant-index'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1],
      title: 'tenant',
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'UserIndex',
    component: () => import('@/views/user/index'),
    beforeEnter: requireAuthorization,
    meta: {
      title: 'lookUp',
      showInMenu: true,
      allowedRoles: [1, 2, 3, 4],
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/users/create',
    name: 'UserCreate',
    component: () => import('@/views/user/create'),
    beforeEnter: requireAuthorization,
    meta: {
      title: 'create',
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/users/:id/:company/:service/update',
    name: 'UserUpdate',
    component: () => import('@/views/user/update'),
    beforeEnter: requireAuthorization,
    meta: {
      title: 'true',
      layout: 'private',
      requiresAuth: true,
      allowedRoles: [1, 2],
    },
  },
  {
    path: '/newsletters',
    name: 'NewsletterIndex',
    component: () => import('@/views/newsletter/newsletter-index'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1, 2, 3],
      title: 'newsletter',
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/newsletters/create',
    name: 'NewsletterCreate',
    component: () => import('@/views/newsletter/newsletter-create'),
    beforeEnter: requireAuthorization,
    meta: {
      allowedRoles: [1, 2, 3],
      title: 'newsletter',
      showInMenu: true,
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/account/login'),
    meta: {
      showInMenu: true,
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/recovery/:service',
    name: 'Recovery',
    component: () => import('@/views/account/recovery'),
    meta: {
      title: '',
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/changePassword/:id/:code/:service',
    name: 'ChangePasswordIndex',
    component: () => import('@/views/account/change-password'),
    meta: {
      title: '',
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/serviceFeatures/public/:companyId/:serviceId/:isEmbedded',
    name: 'ServiceFeaturePublic',
    component: () => import('@/views/company-service-feature/public'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/vouchers/public/:companyId/:serviceId/:isEmbedded',
    name: 'VoucherCompanyService',
    component: () => import('@/views/voucher/public'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/vouchers',
    name: 'VouchersIndex',
    component: () => import('@/views/voucher/index'),
    meta: {
      allowedRoles: [1, 2, 3, 4],
      title: i18n.tc('voucher', 2),
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/vouchers/create',
    name: 'VouchersCreate',
    component: () => import('@/views/voucher/create'),
    meta: {
      allowedRoles: [1, 2, 4],
      title: i18n.tc('create', 2),
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/vouchers/:id/update',
    name: 'VouchersUpdate',
    component: () => import('@/views/voucher/update'),
    meta: {
      allowedRoles: [1, 2, 4],
      title: i18n.tc('update', 2),
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/payments/placeToPay/:placeToPayReference/payment/success',
    name: 'PlaceToPayResultPaymentBasic',
    component: () => import('@/views/placeToPay/result-payment-basic'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/payments/placeToPay/:placeToPayReference/subscription/success',
    name: 'PlaceToPayResultPaymentSubscribe',
    component: () => import('@/views/placeToPay/result-payment-subscribe'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/account/confirm/email/:id/:code',
    name: 'ConfirmEmailIndex',
    component: () => import('@/views/my_account/confirmEmail'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/account/password/new/:id/:code',
    name: 'NewPassword',
    component: () => import('@/views/my_account/newPassword'),
    meta: {
      title: '',
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/account',
    name: 'MyAccountIndex',
    component: () => import('@/views/my_account/myAccount-index'),
    meta: {
      title: '',
      layout: 'private',
      requiresAuth: true,
    },
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
      layout: 'public',
      requiresAuth: false,
    },
  },
]
const router = new Router({
  mode: 'history',
  routes,
})

Vue.use(
  VueGtag,
  {
    pageTrackerExcludedRoutes: [
      'PlanSelectCreateAccountRegister',
      'PlanSelectCreateAccountRegisterCurrent',
      'PlanSelectCreateAccountRegisterCurrentCompany',
      'PlanSelectPurchaseAdditional',
      'KickOffNominaElectronica',
      'Blank',
      'BlankCompanyServiceFeature',
      'BlankPlansSelect',
      'PlanSelect',
      'AddOnsIndex',
      'AddOnsCreate',
      'AddOnsUpdate',
      'Home',
      'HomeSupport',
      'HomeSellers',
      'CompanyIndex',
      'CompanyUpdate',
      'CompanyCreate',
      'CompanyUpdateLogo',
      'PlanIndex',
      'PlanCreate',
      'PlanUpdate',
      'FeatureIndex',
      'FeatureCreate',
      'FeatureUpdate',
      'TenantIndex',
      'UserIndex',
      'UserCreate',
      'UserUpdate',
      'NewsletterIndex',
      'NewsletterCreate',
      'Login',
      'Recovery',
      'ChangePasswordIndex',
      'ServiceFeaturePublic',
      'VoucherCompanyService',
      'VouchersIndex',
      'VouchersCreate',
      'VouchersUpdate',
      'PlaceToPayResultPaymentBasic',
      'PlaceToPayResultPaymentSubscribe',
      'ConfirmEmailIndex',
      'NewPassword',
      'MyAccountIndex',
      '401',
      '404',
      '401',
      'Login',
    ],
    onError(test, hola) {
      console.log('%c⧭', 'color: #ff0000', test, hola)
    },
    onBeforeTrack(test, hola) {
      console.log('%c⧭', 'color: #ff0000', test, hola)
    },
    onAfterTrack(test, hola) {
      console.log('%c⧭', 'color: #ff0000', test, hola)
    },
    config: {
      id: 'GTM-TGM5QB3',
    },
  },
  router,
)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('masters')) {
      const { roleId } = JSON.parse(localStorage.getItem('masters'))
      if (
        to &&
        to.meta &&
        to.meta.allowedRoles &&
        !to.meta.allowedRoles.includes(+roleId)
      ) {
        next({
          name: '401',
        })

        return ''
      }
      next()

      return ''
    }
    next({
      name: 'Login',
    })
  } else {
    next()
  }
})
export default router
