<template>
  <el-select :value="value" placeholder="Partner..." clearable :init="false" value-key="id" :disabled="disabled" @change="onChange">
    <el-option v-for="item in options" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
  </el-select>
</template>
<script>

export default {
  props: ['value', 'init', 'disabled'],
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('set-partner', this.options.find(i => i.id === value))
    },
    get() {
      const _this = this

      // _this.options = []
      _this.$http.get('companies/findPartners').then(response => {
        _this.options = response.body
        if (_this.init) {
          _this.$emit('input', _this.options[1].id)
          _this.$emit('set-partner', _this.options[1])
        }
      }, response => {

      })
    },
  },
}
</script>
