<template>
  <div v-loading="loading">
    <el-select :value="value" :placeholder="label" @change="onChange">
      <el-option :label="$tc('firstPeriod', 2)" value="FirstPeriods">
      </el-option>
    </el-select>
  </div>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('', 'type'),
  data() {
    return model()
  },
}
</script>
