<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      :placeholder="label"
      multiple
      filterable
      default-first-option
      @change="onChange"
    >
      <el-option label="Gran contribuyente" value="O-13">
        <span style="float: left">Gran contribuyente</span>
        <span style="float: right; color: #8492a6; font-size: 13px">O-13</span>
      </el-option>
      <el-option label="Autorretenedor" value="O-15">
        <span style="float: left">Autorretenedor</span>
        <span style="float: right; color: #8492a6; font-size: 13px">O-15</span>
      </el-option>
      <el-option label="Agente de retención IVA" value="O-23">
        <span style="float: left">Agente de retención IVA</span>
        <span style="float: right; color: #8492a6; font-size: 13px">O-23</span>
      </el-option>
      <el-option label="Régimen simple de tributación" value="O-47">
        <span style="float: left">Régimen simple de tributación</span>
        <span style="float: right; color: #8492a6; font-size: 13px">O-47</span>
      </el-option>
      <el-option label="No responsable" value="R-99-PN">
        <span style="float: left">No responsable</span>
        <span style="float: right; color: #8492a6; font-size: 13px">R-99-PN</span>
      </el-option>
    </el-select>
  </div>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('', 'recurrence'),
  data() {
    return model()
  },
}
</script>
