export function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false)
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler)
  }
}

// Send a message to the parent
export function sendMessage(msg) {
  window.parent.postMessage(msg, '*')
}
