<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    clearable
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :value="item.id" :label="item.addOnName">
      <span style="float: left">{{ item.addOnName }}</span>
      <small v-if="item.addOnBillRecurrence === 'ByPlan'">/ {{ $tc('byRecurrencePlan') }}.</small>
      <small v-if="item.addOnBillRecurrence === 'Initial'">/ {{ $tc('paymentOnlyTime') }}.</small>
      &nbsp;
      <el-tooltip
        v-if="item.addOnDescription"
        :content="item.addOnDescription"
        class="item help"
        effect="dark"
        placement="top"
      >
        <i class="fad fa-question">
        </i>
      </el-tooltip>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.addOnPrice | formatToCurrencyWithIsoCode }}</span>
    </el-option>
  </el-select>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('add-ons', 'addOn'),
  props: {
    planId: { required: false },
    serviceId: { required: true },
  },
  data() {
    return model()
  },
  computed: {
    serviceWithPlan() {
      return `${this.serviceId}-${this.planId}`
    },
  },
  watch: {
    serviceWithPlan(newValue) {
      this.getAddOns()
    },
  },
  mounted() {
    this.getAddOns()
  },
  methods: {
    getAddOns() {
      const _this = this
      if (_this.serviceId && _this.planId) {
        _this.get(`${_this.serviceId}/${_this.planId}/findByServiceByPlan`, {
          queryStringParams: {
            onlyEnabled: true,
          },
        })
      } else if (_this.serviceId) {
        _this.get(`${_this.serviceId}/findByService`, {
          queryStringParams: {
            onlyEnabled: true,
          },
        })
      }
    },
  },
}

</script>
