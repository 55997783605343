import add from 'date-fns/add'

export function getMonthName(idx) {
  const objDate = new Date()

  objDate.setDate(1)
  objDate.setMonth(idx - 1)

  return objDate.toLocaleString('es-co', {
    month: 'long',
  })
}

export const addDays = (date, days) => {
  let newDate = new Date(date)
  newDate = new Date(date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)))

  return newDate
}

export const addMonths = (date, months) => {
  if (months === 12) {
    return add(new Date(date), {
      years: 1,
    })
  }

  return add(new Date(date), {
    months,
  })
}

export const lastDayOfMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0)
