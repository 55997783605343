import decode from 'jwt-decode'
import router from '../../router'

// import Vue from 'vue'
export const ACCESS_TOKEN_KEY = 'el_rey_mono_kung_fu'

export function removeAuth() {
  localStorage.removeItem('masters')
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(`${ACCESS_TOKEN_KEY}_expires_in`)
  router.push({ name: 'Login' })
}

export function logout() {
  removeAuth()
}

export function isLoggedIn() {
  const token = getAccessToken(ACCESS_TOKEN_KEY)

  return token && !isTokenExpired(ACCESS_TOKEN_KEY)
}

export function requireAuthorization(to, from, next) {
  if (!isLoggedIn()) {
    removeAuth()
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (to.meta.privileges) {
    const { roleId } = JSON.parse(localStorage.getItem('masters'))
    if (to.meta.privileges.includes(roleId)) {
      next()
    } else {
      next({ name: '404', replace: true })
    }
  } else {
    next()
  }
}

export function getAccessToken(tokenKey) {
  return localStorage.getItem(tokenKey)
}

function getTokenExpirationDate(tokenKey) {
  if (getAccessToken(tokenKey) === 'undefined') {
    return null
  }
  const token = decode(getAccessToken(tokenKey))
  if (!token.exp) {
    return null
  }
  const date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired(tokenKey) {
  const expirationDate = getTokenExpirationDate(tokenKey)

  return expirationDate < new Date()
}
