<template>
  <el-select
    :value="value"
    :placeholder="$tc('identificationType')"
    filterable
    clearable
    @change="onChange"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.identificationTypeName"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      options: []
    }
  },
  mounted() {
    const _this = this
    _this.$http.get('identificationTypes').then(
      (response) => {
        if (response.body.length > 0) {
          _this.options = response.body.filter(
            (item) => !['RC', 'TI', 'NUIP'].includes(item.identificationTypeCode)
          )
        } else {
          _this.options = []
        }
        _this.$emit('input', '31')
      },
      (response) => {}
    )

    // const _this = this
    // db.identificationType.toArray().then(data => {
    //   this.options = data
    // })
  },
  methods: {
    onChange(event) {
      const _this = this
      _this.$emit('input', event)
      if (event) {
        _this.$emit(
          'set-identification-type-code',
          _this.options.find((k) => k.id === event).identificationTypeCode
        )
        _this.$emit(
          'set-identification-type-name',
          _this.options.find((k) => k.id === event).identificationTypeName
        )
      } else {
        _this.$emit('set-identification-type-code', '')
        _this.$emit('set-identification-type-name', '')
      }
    }
  }
}
</script>
