<template>
  <div v-loading="loading">
    <el-select :value="value" :placeholder="label" @change="onChange">
      <el-option :label="$tc('open', 0)" value="Open" :disabled=" !roleIsAdministrator"></el-option>
      <el-option :label="$tc('annuled', 0)" value="Annuled"></el-option>
      <el-option v-if="roleIsAdministrator" :label="$tc('paid')" value="Paid"></el-option>
      <el-option v-if="roleIsAdministrator" :label="$tc('paidWithoutInvoice')" value="PaidWithoutInvoice"></el-option>
    </el-select>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import select, { model } from './common'

export default {
  extends: select('', 'status'),
  data() {
    return model()
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
  },
}
</script>
