<template>
  <el-select
    :value="value"
    :clearable="clearable"
    :placeholder="label"
    reserve-keyword
    @change="onChange"
  >
    <el-option
      value="periodicidad"
      :label="$tc('periodicity')"
    ></el-option>
    <el-option
      value="demanda"
      :label="$tc('demand')"
    ></el-option>
    <el-option
      value="contador"
      :label="$tc('accountant')"
    ></el-option>
    <el-option
      value="partner"
      :label="$tc('partner')"
    ></el-option>
    <el-option
      value="demandaGlobal"
      :label="$tc('globalDemand')"
    ></el-option>
    <el-option
      value="demandaIndividual"
      :label="$tc('individualDemand')"
    ></el-option>
    <el-option
      value="paquete"
      :label="$tc('package', 0)"
    ></el-option>
    <el-option
      value="paquetePeriodicidad"
      label="Paquete para periodicidad"
    ></el-option>
  </el-select>
</template>
<script>
import select from './common'

export default {
  extends: select('', 'mode'),
}
</script>
