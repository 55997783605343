export default [
  {
    code: '1104',
    name: 'UNIVERSIDAD NACIONAL DE COLOMBIA'
  },
  {
    code: '1105',
    name: 'UNIVERSIDAD PEDAGOGICA NACIONAL'
  },
  {
    code: '1106',
    name: 'UNIVERSIDAD PEDAGOGICA Y TECNOLOGICA DE COLOMBIA - UPTC'
  },
  {
    code: '111',
    name: 'UNIVERSIDAD DEL CAUCA'
  },
  {
    code: '1111',
    name: 'UNIVERSIDAD TECNOLOGICA DE PEREIRA - UTP'
  },
  {
    code: '1112',
    name: 'UNIVERSIDAD DE CALDAS'
  },
  {
    code: '1114',
    name: 'UNIVERSIDAD SURCOLOMBIANA'
  },
  {
    code: '1115',
    name: 'UNIVERSIDAD DE LA AMAZONIA'
  },
  {
    code: '1117',
    name: 'UNIVERSIDAD MILITAR-NUEVA GRANADA'
  },
  {
    code: '1118',
    name: 'UNIVERSIDAD TECNOLOGICA DEL CHOCO-DIEGO LUIS CORDOBA'
  },
  {
    code: '1119',
    name: 'UNIVERSIDAD DE LOS LLANOS'
  },
  {
    code: '112',
    name: 'UNIVERSIDAD POPULAR DEL CESAR'
  },
  {
    code: '1122',
    name: 'UNIVERSIDAD DEL PACIFICO'
  },
  {
    code: '1123',
    name: 'UNIVERSIDAD POPULAR DEL CESAR'
  },
  {
    code: '1201',
    name: 'UNIVERSIDAD DE ANTIOQUIA'
  },
  {
    code: '1202',
    name: 'UNIVERSIDAD DEL ATLANTICO'
  },
  {
    code: '1203',
    name: 'UNIVERSIDAD DEL VALLE'
  },
  {
    code: '1204',
    name: 'UNIVERSIDAD INDUSTRIAL DE SANTANDER'
  },
  {
    code: '1205',
    name: 'UNIVERSIDAD DE CARTAGENA'
  },
  {
    code: '1206',
    name: 'UNIVERSIDAD DE NARIÑO'
  },
  {
    code: '1207',
    name: 'UNIVERSIDAD DEL TOLIMA'
  },
  {
    code: '1208',
    name: 'UNIVERSIDAD DEL QUINDIO'
  },
  {
    code: '1209',
    name: 'UNIVERSIDAD FRANCISCO DE PAULA SANTANDER'
  },
  {
    code: '1212',
    name: 'UNIVERSIDAD DE PAMPLONA'
  },
  {
    code: '1213',
    name: 'UNIVERSIDAD DEL MAGDALENA - UNIMAGDALENA'
  },
  {
    code: '1214',
    name: 'UNIVERSIDAD DE CUNDINAMARCA-UDEC'
  },
  {
    code: '1217',
    name: 'UNIVERSIDAD DE SUCRE'
  },
  {
    code: '1218',
    name: 'UNIVERSIDAD DE LA GUAJIRA'
  },
  {
    code: '1219',
    name: 'UNIVERSIDAD DE ANTIOQUIA'
  },
  {
    code: '1301',
    name: 'UNIVERSIDAD DISTRITAL-FRANCISCO JOSE DE CALDAS'
  },
  {
    code: '1701',
    name: 'PONTIFICIA UNIVERSIDAD JAVERIANA'
  },
  {
    code: '1703',
    name: 'UNIVERSIDAD INCCA DE COLOMBIA'
  },
  {
    code: '1704',
    name: 'UNIVERSIDAD SANTO TOMAS'
  },
  {
    code: '1706',
    name: 'UNIVERSIDAD EXTERNADO DE COLOMBIA'
  },
  {
    code: '1707',
    name: 'FUNDACION UNIVERSIDAD DE BOGOTA - JORGE TADEO LOZANO'
  },
  {
    code: '1709',
    name: 'UNIVERSIDAD CENTRAL'
  },
  {
    code: '171',
    name: 'UNIVERSIDAD PONTIFICIA BOLIVARIANA'
  },
  {
    code: '1712',
    name: 'UNIVERSIDAD EAFIT-'
  },
  {
    code: '1713',
    name: 'UNIVERSIDAD DEL NORTE'
  },
  {
    code: '1714',
    name: 'COLEGIO MAYOR DE NUESTRA SEÑORA DEL ROSARIO'
  },
  {
    code: '1715',
    name: 'FUNDACION UNIVERSIDAD DE AMERICA'
  },
  {
    code: '1716',
    name: 'UNIVERSIDAD DE SAN BUENAVENTURA'
  },
  {
    code: '1719',
    name: 'UNIVERSIDAD CATOLICA DE COLOMBIA'
  },
  {
    code: '172',
    name: 'UNIVERSIDAD MARIANA'
  },
  {
    code: '1722',
    name: 'UNIVERSIDAD DE MANIZALES'
  },
  {
    code: '1723',
    name: 'UNIVERSIDAD PONTIFICIA BOLIVARIANA'
  },
  {
    code: '1724',
    name: 'UNIVERSIDAD DE SAN BUENAVENTURA'
  },
  {
    code: '1725',
    name: 'FUNDACION UNIVERSIDAD AUTONOMA DE COLOMBIA -FUAC-'
  },
  {
    code: '1726',
    name: 'UNIVERSIDAD CATOLICA DE ORIENTE -UCO'
  },
  {
    code: '1727',
    name: 'UNIVERSIDAD PONTIFICIA BOLIVARIANA'
  },
  {
    code: '1728',
    name: 'UNIVERSIDAD SERGIO ARBOLEDA'
  },
  {
    code: '1729',
    name: 'UNIVERSIDAD EL BOSQUE'
  },
  {
    code: '173',
    name: 'UNIVERSIDAD PONTIFICIA BOLIVARIANA'
  },
  {
    code: '1731',
    name: 'UNIVERSIDAD CATOLICA DE COLOMBIA'
  },
  {
    code: '1732',
    name: 'UNIVERSIDAD SANTO TOMAS'
  },
  {
    code: '1733',
    name: 'UNIVERSIDAD SERGIO ARBOLEDA'
  },
  {
    code: '1734',
    name: 'UNIVERSIDAD DE BOYACA UNIBOYACA'
  },
  {
    code: '1735',
    name: 'UNIVERSIDAD MANUELA BELTRAN-UMB-'
  },
  {
    code: '1801',
    name: 'UNIVERSIDAD LA GRAN COLOMBIA'
  },
  {
    code: '1803',
    name: 'UNIVERSIDAD DE LA SALLE'
  },
  {
    code: '1804',
    name: 'UNIVERSIDAD AUTONOMA DEL CARIBE- UNIAUTONOMA'
  },
  {
    code: '1805',
    name: 'UNIVERSIDAD SANTIAGO DE CALI'
  },
  {
    code: '1809',
    name: 'UNIVERSIDAD LIBRE'
  },
  {
    code: '1812',
    name: 'UNIVERSIDAD DE MEDELLIN'
  },
  {
    code: '1813',
    name: 'UNIVERSIDAD DE LOS ANDES'
  },
  {
    code: '1814',
    name: 'UNIVERSIDAD AUTONOMA LATINOAMERICANA-UNAULA-'
  },
  {
    code: '1815',
    name: 'CORPORACION UNIVERSIDAD PILOTO DE COLOMBIA'
  },
  {
    code: '1816',
    name: 'UNIVERSIDAD COOPERATIVA DE COLOMBIA'
  },
  {
    code: '1822',
    name: 'CORPORACION UNIVERSIDAD PILOTO DE COLOMBIA'
  },
  {
    code: '1823',
    name: 'UNIVERSIDAD AUTONOMA DE BUCARAMANGA-UNAB-'
  },
  {
    code: '1824',
    name: 'UNIVERSIDAD METROPOLITANA'
  },
  {
    code: '1825',
    name: 'UNIVERSIDAD AUTONOMA DE MANIZALES'
  },
  {
    code: '1826',
    name: 'UNIVERSIDAD ANTONIO NARIÑO'
  },
  {
    code: '1827',
    name: 'UNIVERSIDAD CATOLICA DE MANIZALES'
  },
  {
    code: '1828',
    name: 'UNIVERSIDAD ICESI'
  },
  {
    code: '1829',
    name: 'UNIVERSIDAD SANTIAGO DE CALI'
  },
  {
    code: '183',
    name: 'UNIVERSIDAD AUTONOMA DE OCCIDENTE'
  },
  {
    code: '1831',
    name: 'UNIVERSIDAD DE IBAGUE'
  },
  {
    code: '1833',
    name: 'UNIVERSIDAD DEL SINU - ELIAS BECHARA ZAINUM - UNISINU -'
  },
  {
    code: '2102',
    name: 'UNIVERSIDAD NACIONAL ABIERTA Y A DISTANCIA UNAD'
  },
  {
    code: '2106',
    name: 'DIRECCION NACIONAL DE ESCUELAS'
  },
  {
    code: '211',
    name: 'COLEGIO MAYOR DE ANTIOQUIA'
  },
  {
    code: '2114',
    name: 'ESCUELA NACIONAL DEL DEPORTE'
  },
  {
    code: '2207',
    name: 'INSTITUTO UNIVERSITARIO DE LA PAZ'
  },
  {
    code: '2208',
    name: 'CONSERVATORIO DEL TOLIMA'
  },
  {
    code: '2209',
    name: 'POLITECNICO COLOMBIANO JAIME ISAZA CADAVID'
  },
  {
    code: '2211',
    name: 'INSTITUCION UNIVERSITARIA BELLAS ARTES Y CIENCIAS DE BOLIVAR'
  },
  {
    code: '2301',
    name: 'UNIDAD CENTRAL DEL VALLE DEL CAUCA'
  },
  {
    code: '2302',
    name: 'INSTITUCION UNIVERSITARIA DE ENVIGADO'
  },
  {
    code: '2701',
    name: 'INSTITUCION UNIVERSITARIA COLEGIOS DE COLOMBIA - UNICOC'
  },
  {
    code: '2702',
    name: 'FUNDACION UNIVERSITARIA DE CIENCIAS DE LA SALUD'
  },
  {
    code: '2704',
    name: 'COLEGIO DE ESTUDIOS SUPERIORES DE ADMINISTRACION-CESA-'
  },
  {
    code: '2707',
    name: 'FUNDACIÓN UNIVERSITARIA JUAN N. CORPAS'
  },
  {
    code: '2708',
    name: 'UNIVERSIDAD CES'
  },
  {
    code: '2709',
    name: 'FUNDACION UNIVERSITARIA SAN MARTIN'
  },
  {
    code: '271',
    name: 'FUNDACION UNIVERSITARIA MONSERRATE -UNIMONSERRATE'
  },
  {
    code: '2712',
    name: 'FUNDACION UNIVERSITARIA KONRAD LORENZ'
  },
  {
    code: '2713',
    name: 'FUNDACION UNIVERSITARIA LOS LIBERTADORES'
  },
  {
    code: '2715',
    name: 'FUNDACION UNIVERSITARIA DE POPAYAN'
  },
  {
    code: '2719',
    name: 'UNIVERSIDAD CATÓLICA LUIS AMIGÓ'
  },
  {
    code: '272',
    name: 'FUNDACION UNIVERSITARIA JUAN DE CASTELLANOS'
  },
  {
    code: '2721',
    name: 'FUNDACION UNIVERSITARIA MARIA CANO'
  },
  {
    code: '2722',
    name: 'FUNDACION CENTRO UNIVERSITARIO DE BIENESTAR RURAL'
  },
  {
    code: '2723',
    name: 'FUNDACION UNIVERSITARIA AGRARIA DE COLOMBIA -UNIAGRARIA-'
  },
  {
    code: '2724',
    name: 'FUNDACION UNIVERSITARIA DE SAN GIL - UNISANGIL -'
  },
  {
    code: '2727',
    name: 'FUNDACION UNIVERSITARIA-CEIPA-'
  },
  {
    code: '2728',
    name: 'FUNDACION UNIVERSITARIA DEL AREA ANDINA'
  },
  {
    code: '273',
    name: 'FUNDACION ESCUELA COLOMBIANA DE REHABILITACION'
  },
  {
    code: '2731',
    name: 'FUNDACION UNIVERSITARIA CATOLICA LUMEN GENTIUM - UNICATÓLICA - CALI'
  },
  {
    code: '2732',
    name: 'FUNDACION UNIVERSITARIA CATOLICA DEL NORTE'
  },
  {
    code: '2733',
    name: 'FUNDACIÓN UNIVERSITARIA SAN ALFONSO- FUSA-'
  },
  {
    code: '2736',
    name: 'FUNDACION UNIVERSITARIA SEMINARIO BIBLICO DE COLOMBIA - FUSBC'
  },
  {
    code: '2737',
    name: 'FUNDACION UNIVERSITARIA DEL AREA ANDINA'
  },
  {
    code: '2739',
    name: 'FUNDACION DE ESTUDIOS SUPERIORES UNIVERSITARIOS DE URABA ANTONIO ROLDAN BETANCUR'
  },
  {
    code: '274',
    name: 'INSTITUCION UNIVERSITARIA COLOMBO AMERICANA - UNICA'
  },
  {
    code: '2741',
    name: 'FUNDACION DE ESTUDIOS SUPERIORES - MONSEÑOR ABRAHAM ESCUDERO MONTOYA  - FUNDES'
  },
  {
    code: '2743',
    name: 'UNIVERSIDAD INTERNACIONAL DEL TRÓPICO AMERICANO'
  },
  {
    code: '2744',
    name: 'UNIVERSIDAD CESMAG - UNICESMAG'
  },
  {
    code: '2745',
    name: 'FUNDACIÓN UNIVERSITARIA COMPENSAR'
  },
  {
    code: '2746',
    name: 'FUNDACION UNIVERSITARIA SANITAS'
  },
  {
    code: '2747',
    name: 'INSTITUCIÓN UNIVERSITARIA VISIÓN DE LAS AMÉRICAS'
  },
  {
    code: '2748',
    name: 'FUNDACION UNIVERSITARIA SEMINARIO TEOLOGICO BAUTISTA INTERNACIONAL'
  },
  {
    code: '2805',
    name: 'UNIVERSIDAD SIMON BOLIVAR'
  },
  {
    code: '281',
    name: 'CORPORACION UNIVERSIDAD DE LA COSTA CUC'
  },
  {
    code: '2811',
    name: 'ESCUELA COLOMBIANA DE INGENIERIA JULIO GARAVITO'
  },
  {
    code: '2812',
    name: 'UNIVERSIDAD EAN'
  },
  {
    code: '2813',
    name: 'UNIVERSIDAD EIA'
  },
  {
    code: '2815',
    name: 'CORPORACION UNIVERSITARIA ADVENTISTA - UNAC'
  },
  {
    code: '282',
    name: 'CORPORACION UNIVERSITARIA LASALLISTA'
  },
  {
    code: '2823',
    name: 'CORPORACION UNIVERSITARIA DEL CARIBE - CECAR'
  },
  {
    code: '2824',
    name: 'CORPORACION UNIVERSITARIA DE COLOMBIA IDEAS'
  },
  {
    code: '2825',
    name: 'CORPORACION UNIVERSITARIA RAFAEL NUÑEZ'
  },
  {
    code: '2827',
    name: 'CORPORACION UNIVERSITARIA DEL META - UNIMETA'
  },
  {
    code: '2828',
    name: 'CORPORACION UNIVERSITARIA DEL HUILA-CORHUILA-'
  },
  {
    code: '2829',
    name: 'CORPORACION UNIVERSITARIA MINUTO DE DIOS -UNIMINUTO-'
  },
  {
    code: '283',
    name: 'CORPORACION UNIVERSITARIA IBEROAMERICANA'
  },
  {
    code: '2831',
    name: 'CORPORACION UNIVERSITARIA DE CIENCIA Y DESARROLLO - UNICIENCIA'
  },
  {
    code: '2832',
    name: 'UNIVERSIDAD DE SANTANDER - UDES'
  },
  {
    code: '2833',
    name: 'CORPORACION UNIVERSITARIA REMINGTON'
  },
  {
    code: '2834',
    name: 'UNIVERSITARIA AGUSTINIANA- UNIAGUSTINIANA'
  },
  {
    code: '2836',
    name: 'CORPORACION UNIVERSITARIA EMPRESARIAL DE SALAMANCA'
  },
  {
    code: '2837',
    name: 'CORPORACION UNIVERSITARIA REPUBLICANA'
  },
  {
    code: '2838',
    name: 'CORPORACION COLEGIATURA COLOMBIANA'
  },
  {
    code: '284',
    name: 'CORPORACION UNIVERSITARIA EMPRESARIAL ALEXANDER VON HUMBOLDT - CUE'
  },
  {
    code: '2841',
    name: 'CORPORACION UNIVERSITARIA MINUTO DE DIOS -UNIMINUTO-'
  },
  {
    code: '2842',
    name: 'CORPORACION UNIVERSITARIA REFORMADA - CUR -'
  },
  {
    code: '2848',
    name: 'CORPORACION UNIVERSITARIA  UNITEC'
  },
  {
    code: '285',
    name: 'CORPORACION UNIVERSITARIA ANTONIO JOSE DE SUCRE - CORPOSUCRE'
  },
  {
    code: '2901',
    name: 'ESCUELA DE INTELIGENCIA Y CONTRAINTELIGENCIA BRIGADIER GENERAL RICARDO CHARRY SOLANO'
  },
  {
    code: '2902',
    name: 'ESCUELA DE LOGISTICA'
  },
  {
    code: '2904',
    name: 'ESCUELA SUPERIOR DE GUERRA GENERAL RAFAEL REYES PRIETO'
  },
  {
    code: '2905',
    name: 'CENTRO DE EDUCACION MILITAR - CEMIL'
  },
  {
    code: '2906',
    name: 'ESCUELA DE POSTGRADOS DE LA FUERZA AEREA COLOMBIANA CAPITAN JOSE EDMUNDO SANDOVAL - EPFAC'
  },
  {
    code: '3102',
    name: 'INSTITUTO SUPERIOR DE EDUCACION RURAL-ISER-'
  },
  {
    code: '3104',
    name: 'COLEGIO MAYOR DEL CAUCA'
  },
  {
    code: '3107',
    name: 'INSTITUCIÓN UNIVERSITARIA PASCUAL BRAVO'
  },
  {
    code: '3114',
    name: 'ESCUELA NAVAL DE SUBOFICIALES ARC BARRANQUILLA'
  },
  {
    code: '3116',
    name: 'INSTITUTO TECNOLOGICO DEL PUTUMAYO'
  },
  {
    code: '3117',
    name: 'INSTITUCIÓN UNIVERSITARIA ITSA'
  },
  {
    code: '3201',
    name: 'UNIDADES TECNOLOGICAS DE SANTANDER'
  },
  {
    code: '3204',
    name: 'TECNOLOGICO DE ANTIOQUIA'
  },
  {
    code: '3301',
    name: 'INSTITUCION UNIVERSITARIA ANTONIO JOSE CAMACHO'
  },
  {
    code: '3302',
    name: 'INSTITUTO TECNOLOGICO METROPOLITANO'
  },
  {
    code: '3303',
    name: 'ESCUELA SUPERIOR TECNOLOGICA DE ARTES DEBORA ARANGO'
  },
  {
    code: '3702',
    name: 'FUNDACION TECNOLOGICA AUTONOMA DE BOGOTA-FABA-'
  },
  {
    code: '3703',
    name: 'INSTITUCION UNIVERSITARIA ESCOLME'
  },
  {
    code: '3705',
    name: 'FUNDACION UNIVERSITARIA TECNOLOGICO COMFENALCO - CARTAGENA'
  },
  {
    code: '371',
    name: 'FUNDACION UNIVERSITARIA ANTONIO DE AREVALO - UNITECNAR'
  },
  {
    code: '3712',
    name: 'FUNDACION CENTRO DE INVESTIGACION DOCENCIA Y CONSULTORIA ADMINISTRATIVA-F-CIDCA-'
  },
  {
    code: '3715',
    name: 'FUNDACION TECNOLOGICA AUTONOMA DEL PACIFICO'
  },
  {
    code: '3718',
    name: 'FUNDACION DE ESTUDIOS SUPERIORES COMFANORTE -F.E.S.C.-'
  },
  {
    code: '3719',
    name: 'INSTITUCION UNIVERSITARIA LATINA - UNILATINA'
  },
  {
    code: '3724',
    name: 'FUNDACION TECNOLOGICA DE MADRID'
  },
  {
    code: '3725',
    name: 'FUNDACION TECNOLOGICA ALBERTO MERANI'
  },
  {
    code: '3801',
    name: 'CORPORACION DE ESTUDIOS TECNOLOGICOS DEL NORTE DEL VALLE'
  },
  {
    code: '3802',
    name: 'CENTRO EDUCACIONAL DE COMPUTOS Y SISTEMAS-CEDESISTEMAS-'
  },
  {
    code: '3803',
    name: 'CORPORACION UNIVERSITARIA CENTRO SUPERIOR - UNICUCES'
  },
  {
    code: '3807',
    name: 'ESCUELA DE TECNOLOGIAS DE ANTIOQUIA -ETA-'
  },
  {
    code: '3808',
    name: 'CORPORACION TECNOLOGICA DE BOGOTA - CTB'
  },
  {
    code: '3809',
    name: 'INSTITUTO SUPERIOR DE CIENCIAS SOCIALES Y ECONOMICO FAMILIARES-ICSEF-'
  },
  {
    code: '3811',
    name: 'CORPORACION DE EDUCACION DEL NORTE DEL TOLIMA - COREDUCACION'
  },
  {
    code: '3812',
    name: 'INSTITUCION UNIVERSITARIA MARCO FIDEL SUAREZ - IUMAFIS'
  },
  {
    code: '3817',
    name: 'CORPORACION UNIVERSITARIA AUTONOMA DE NARIÑO -AUNAR-'
  },
  {
    code: '3819',
    name: 'CORPORACION TECNOLOGICA INDUSTRIAL COLOMBIANA - TEINCO'
  },
  {
    code: '382',
    name: 'CORPORACION ACADEMIA TECNOLOGICA DE COLOMBIA -ATEC-'
  },
  {
    code: '3822',
    name: 'POLITECNICO ICAFT'
  },
  {
    code: '3824',
    name: 'ESCUELA SUPERIOR DE CIENCIAS EMPRESARIALES - ECIEM'
  },
  {
    code: '3826',
    name: 'CORPORACION INTERNACIONAL PARA EL DESARROLLO EDUCATIVO -CIDE-'
  },
  {
    code: '3827',
    name: 'POLITECNICO SANTAFE DE BOGOTA'
  },
  {
    code: '3828',
    name: 'CORPORACIÓN TECNOLÓGICA DE EDUCACIÓN SUPERIOR SAPIENZA -CTE-'
  },
  {
    code: '383',
    name: 'CORPORACION UNIVERSAL DE INVESTIGACION Y TECNOLOGIA -CORUNIVERSITEC-'
  },
  {
    code: '3831',
    name: 'CORPORACION UNIVERSITARIA COMFACAUCA - UNICOMFACAUCA'
  },
  {
    code: '3901',
    name: 'ESCUELA DE FORMACION DE INFANTERIA DE MARINA'
  },
  {
    code: '3902',
    name: 'ESCUELA  MILITAR DE SUBOFICIALES SARGENTO INOCENCIO CHINCA'
  },
  {
    code: '4101',
    name: 'INSTITUTO DE EDUCACION TECNICA PROFESIONAL DE ROLDANILLO'
  },
  {
    code: '4102',
    name: 'INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN JUAN DEL CESAR'
  },
  {
    code: '4106',
    name: 'INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN ANDRES'
  },
  {
    code: '4108',
    name: 'ESCUELA TECNOLOGICA INSTITUTO TECNICO CENTRAL'
  },
  {
    code: '4109',
    name: 'INSTITUTO TECNICO NACIONAL DE COMERCIO SIMON RODRIGUEZ - INTENALCO'
  },
  {
    code: '411',
    name: 'INSTITUTO TOLIMENSE DE FORMACION TECNICA PROFESIONAL'
  },
  {
    code: '4111',
    name: 'INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL - HUMBERTO VELASQUEZ GARCIA'
  },
  {
    code: '4112',
    name: 'COLEGIO INTEGRADO NACIONAL ORIENTE DE CALDAS - IES CINOC'
  },
  {
    code: '4701',
    name: 'FUNDACION ACADEMIA DE DIBUJO PROFESIONAL'
  },
  {
    code: '4702',
    name: 'FUNDACION DE EDUCACION SUPERIOR SAN JOSE -FESSANJOSE-'
  },
  {
    code: '4704',
    name: 'FUNDACION PARA LA EDUCACION SUPERIOR REAL DE COLOMBIA'
  },
  {
    code: '4705',
    name: 'FUNDACION CENTRO DE EDUCACION SUPERIORINVESTIGACION Y PROFESIONALIZACION -CEDINPRO-'
  },
  {
    code: '4708',
    name: 'FUNDACION ESCUELA COLOMBIANA DE HOTELERIA Y TURISMO-ECOTET-'
  },
  {
    code: '4709',
    name: 'INSTITUCION UNIVERSITARIA EAM'
  },
  {
    code: '471',
    name: 'FUNDACIÓN POLITÉCNICO MINUTO DE DIOS - TEC MD'
  },
  {
    code: '4719',
    name: 'FUNDACION DE EDUCACION SUPERIOR NUEVA AMERICA'
  },
  {
    code: '4721',
    name: 'FUNDACION UNIVERSITARIA HORIZONTE'
  },
  {
    code: '4727',
    name: 'POLITECNICO INTERNACIONAL INSTITUCION DE EDUCACION SUPERIOR'
  },
  {
    code: '4801',
    name: 'CORPORACION ACADEMIA SUPERIOR DE ARTES'
  },
  {
    code: '4803',
    name: 'CORPORACION POLITECNICO COLOMBO ANDINO'
  },
  {
    code: '4806',
    name: 'CORPORACION CENTRO DE ESTUDIOS ARTISTICOS Y TECNICOS-CEART-'
  },
  {
    code: '4808',
    name: 'CORPORACION REGIONAL DE EDUCACION SUPERIOR-CRES-DE CALI'
  },
  {
    code: '481',
    name: 'CORPORACION UNIVERSITARIA CENDA'
  },
  {
    code: '481',
    name: 'CORPORACION UNIVERSITARIA CENDA'
  },
  {
    code: '4812',
    name: 'CORPORACION DE EDUCACION SUPERIOR SURAMERICA'
  },
  {
    code: '4813',
    name: 'CORPORACION UNIFICADA NACIONAL DE EDUCACION SUPERIOR-CUN-'
  },
  {
    code: '4817',
    name: 'CORPORACION EDUCATIVA DEL LITORAL'
  },
  {
    code: '4818',
    name: 'CORPORACION UNIVERSITARIA LATINOAMERICANA - CUL'
  },
  {
    code: '4822',
    name: 'CORPORACION ESCUELA DE ARTES Y LETRAS'
  },
  {
    code: '4826',
    name: 'CORPORACION UNIVERSITARIA REGIONAL DEL CARIBE -IAFIC-'
  },
  {
    code: '4829',
    name: 'CORPORACION INTERAMERICANA DE EDUCACION SUPERIOR-CORPOCIDES'
  },
  {
    code: '4832',
    name: 'CORPORACION INSTITUTO SUPERIOR DE EDUCACION SOCIAL-ISES-'
  },
  {
    code: '4835',
    name: 'CORPORACION UNIVERSITARIA TALLER CINCO CENTRO DE DISEÑO'
  },
  {
    code: '4837',
    name: 'CORPORACION UNIVERSITARIA DE CIENCIAS EMPRESARIALES EDUCACION Y SALUD -CORSALUD-'
  },
  {
    code: '5801',
    name: 'CORPORACION ESCUELA TECNOLOGICA DEL ORIENTE'
  },
  {
    code: '5802',
    name: 'UNIVERSIDAD ECCI'
  },
  {
    code: '9102',
    name: 'ESCUELA DE SUBOFICIALES DE LA FUERZA AEREA COLOMBIANA ANDRES M. DIAZ'
  },
  {
    code: '9104',
    name: 'ESCUELA MILITAR DE CADETES GENERAL JOSE MARIA CORDOVA'
  },
  {
    code: '9105',
    name: 'ESCUELA NAVAL DE CADETES ALMIRANTE PADILLA'
  },
  {
    code: '9107',
    name: 'ESCUELA DE INGENIEROS MILITARES'
  },
  {
    code: '9107',
    name: 'ESCUELA DE INGENIEROS MILITARES'
  },
  {
    code: '9108',
    name: 'INSTITUTO CARO Y CUERVO'
  },
  {
    code: '9116',
    name: 'FUNDACION UNIVERSITARIA CLARETIANA - UNICLARETIANA'
  },
  {
    code: '9117',
    name: 'ESCUELA INTERNACIONAL DE ESTUDIOS SUPERIORES - INTER'
  },
  {
    code: '9119',
    name: 'CORPORACION UNIVERSITARIA AMERICANA'
  },
  {
    code: '912',
    name: 'FUNDACION UNIVERSITARIA BELLAS ARTES'
  },
  {
    code: '9121',
    name: 'FUNDACION UNIVERSITARIA COLOMBO INTERNACIONAL - UNICOLOMBO'
  },
  {
    code: '9122',
    name: 'UNIVERSIDAD MANUELA BELTRAN-UMB-'
  },
  {
    code: '9123',
    name: 'UNIVERSIDAD ANTONIO NARIÑO'
  },
  {
    code: '9124',
    name: 'TECNOLOGICO COREDI'
  },
  {
    code: '9125',
    name: 'UNIVERSIDAD DE ANTIOQUIA'
  },
  {
    code: '9126',
    name: 'CORPORACION TECNOLOGICA INDOAMERICA'
  },
  {
    code: '9127',
    name: 'CORPORACION UNIVERSITARIA DE SABANETA - UNISABANETA'
  },
  {
    code: '9128',
    name: 'LCI - FUNDACION TECNOLOGICA'
  },
  {
    code: '9129',
    name: 'FUNDACION UNIVERSITARIA CAFAM -UNICAFAM'
  },
  {
    code: '9131',
    name: 'FUNDACIÓN UNIVERSITARIA CERVANTES SAN AGUSTÍN - UNICERVANTES'
  },
  {
    code: '9132',
    name: 'FUNDACION UNIVERSITARIA CIEO - UNICIEO'
  },
  {
    code: '9899',
    name: 'INSTITUCION UNIVERSITARIA DE COLOMBIA - UNIVERSITARIA DE COLOMBIA'
  },
  {
    code: '99',
    name: 'CORPORACION UNIVERSITARIA U DE COLOMBIA'
  },
  {
    code: '9902',
    name: 'FUNDACION UNIVERSITARIA COMFENALCO SANTANDER'
  },
  {
    code: '9903',
    name: 'CORPORACIÓN COLSUBSIDIO EDUCACIÓN TECNOLÓGICA - CET'
  },
  {
    code: '9904',
    name: 'FUNDACION UNIVERSITARIA COLOMBO GERMANA'
  },
  {
    code: '9905',
    name: 'FUNDACION ESCUELA TECNOLOGICA DE NEIVA - JESUS OVIEDO PEREZ -FET'
  },
  {
    code: '9906',
    name: 'CORPORACION UNIVERSITARIA PARA EL DESARROLLO EMPRESARIAL Y SOCIAL- CUDES'
  },
  {
    code: '9907',
    name: 'FUNDACION UNIVERSITARIA NAVARRA - UNINAVARRA'
  },
  {
    code: '9909',
    name: 'UNIVERSIDAD DEL VALLE'
  },
  {
    code: '991',
    name: 'FUNDACION UNIVERSITARIA LUIS G. PAEZ - UNILUISGPAEZ'
  },
  {
    code: '9911',
    name: 'UNIVERSIDAD DEL VALLE'
  },
  {
    code: '9913',
    name: 'CORPORACION UNIVERSITARIA DE ASTURIAS'
  },
  {
    code: '9914',
    name: 'ESEIT - ESCUELA SUPERIOR DE EMPRESA INGENIERÍA Y TECNOLOGÍA'
  },
  {
    code: '9915',
    name: 'UNIVERSITARIA VIRTUAL INTERNACIONAL'
  },
  {
    code: '9919',
    name: 'TECNOLOGICO DE ENERGIA E INNOVACION-  E-LERNOVA'
  },
  {
    code: '992',
    name: 'UNIVERSIDAD NACIONAL DE COLOMBIA'
  },
  {
    code: '9921',
    name: 'FUNDACION UNIVERSITARIA CATOLICA DEL SUR - UNICATOLICA DEL SUR'
  },
  {
    code: '9922',
    name: 'FUNDACION UNIVERSITARIA COMFAMILIAR RISARALDA'
  },
  {
    code: '9923',
    name: 'CORPORACION UNIVERSITARIA DE CATALUÑA'
  },
  {
    code: '9924',
    name: 'FUNDACION UNIVERSITARIA INTERNACIONAL DE COLOMBIA - UNINCOL'
  },
  {
    code: '9925',
    name: 'FUNDACION UNIVERSITARIA FCV'
  },
  {
    code: '9926',
    name: 'FUNDACION UNIVERSITARIA INTERNACIONAL DE LA RIOJA - UNIR'
  },
  {
    code: '9927',
    name: 'INSTITUCION UNIVERSITARIA DIGITAL DE ANTIOQUIA -IU. DIGITAL'
  },
  {
    code: '9928',
    name: 'FUNDACIÓN UNIVERSITARIA SALESIANA'
  },
  {
    code: '9929',
    name: 'UNIVERSIDAD AUTÓNOMA INDÍGENA INTERCULTURAL - UAIIN'
  },
  {
    code: '993',
    name: 'INSTITUCIÓN UNIVERSITARIA COMANDO DE EDUCACIÓN Y DOCTRINA - CEDOC DEL EJÉRCITO NACIONAL'
  },
  {
    code: '9931',
    name: 'FUNDACIÓN UNIVERSITARIA PATRICIO SYMES'
  },
  {
    code: '9932',
    name: 'FUNDACIÓN UNIVERSITARIA SAN PABLO - UNISANPABLO'
  },
  {
    code: '9934',
    name: 'CENTRO DE ESTUDIOS AERONÁUTICOS - CEA'
  },
  {
    code: '9935',
    name: 'UNIDAD TECNOLÓGICA DEL MAGDALENA MEDIO - UTEM -'
  },
  {
    code: '9936',
    name: 'CORPORACIÓN UNIVERSITARIA AUTÓNOMA DEL NORTE'
  },
  {
    code: '9937',
    name: 'Fundación Universitaria SanMateo '
  },
  {
    code: '9938',
    name: 'Politécnico Gran Colombiano '
  },
  {
    code: '9939',
    name: 'Fundación Universitaria UNIR Colombia '
  },
  {
    code: '994',
    name: 'INCAP Instituto Colombiano de Aprendizaje '
  },
  {
    code: '9936',
    name: 'Politécnico de Antioquia'
  },
  {
    code: '9936',
    name: 'Cedinsi Escuela de Formación para el Trabajo'
  },
  {
    code: '9936',
    name: 'Institución Universitaria Uninpahu '
  },
  {
    code: '9936',
    name: 'Instituto Cenis de Colombia '
  },
  {
    code: '9936',
    name: 'Universidad Cooperativa de Colombia '
  },
  {
    code: '9936',
    name: 'Corporación Universitaria Minuto de Dios '
  },
  {
    code: '9936',
    name: 'Colegio de Contadores Públicos de Colombia '
  },
  {
    code: '9936',
    name: 'Universidad La Gran Colombia '
  },
  {
    code: '9936',
    name: 'Universidad de San Buenaventura '
  },
  {
    code: '9936',
    name: 'Universidad de San Buenaventura '
  },
  {
    code: '9936',
    name: 'Corporación Educativa de Colombia EFORSALUD '
  },
  {
    code: '9936',
    name: 'Instituto Nacional de Contadores Públicos de Colombia '
  },
  {
    code: '9936',
    name: 'Instituto Triangulo '
  },
  {
    code: '9936',
    name: 'Institución Universitaria Americana '
  },
  {
    code: '9936',
    name: 'Institución Universitaria ITM '
  },
  {
    code: '9936',
    name: 'Universidad de Manizales '
  },
  {
    code: '9936',
    name: 'Universidad del Sinú'
  },
  {
    code: '9936',
    name: 'Universidad Unicervantes '
  },
  {
    code: '9936',
    name: 'Universidad del Valle '
  },
  {
    code: '9936',
    name: 'Fundación Universitaria Católica '
  },
  {
    code: '9936',
    name: 'Fundación Universitaria Los Libertadores '
  },
  {
    code: '9936',
    name: 'Universidad de La Salle '
  },
  {
    code: '9936',
    name: 'Fundación Universitaria CEIPA'
  },
  {
    code: '9936',
    name: 'Universidad del Magdalena '
  },
  {
    code: '9936',
    name: 'Universidad Mariana '
  },
  {
    code: '9936',
    name: 'Corporación Universitaria Comfacauca'
  },
  {
    code: '9936',
    name: 'Corporación Universitaria Comfacauca'
  },
  {
    code: '9936',
    name: 'Universidad Autonoma Latinoamericana '
  },
  {
    code: '9936',
    name: 'Institución Universitaria Antonio José Camacho '
  },
  {
    code: '9936',
    name: 'Corporación Universitaria Autonoma del Cauca '
  },
  {
    code: '9936',
    name: 'Corporación Universitaria Autonoma del Cauca '
  },
  {
    code: '9936',
    name: 'Universidad de Medellín'
  },
  {
    code: '9936',
    name: 'Instituto Universitario Quito Metropolitano'
  },
  {
    code: '9936',
    name: 'Corporación Bolivariana del Norte '
  },
  {
    code: '9936',
    name: 'Academia Cecap '
  },
  {
    code: '9936',
    name: 'Universidad Industrial de Santander '
  },
  {
    code: '9936',
    name: 'Universidad Tecnológica de Bolívar '
  },
  {
    code: '9936',
    name: 'Universidad Colegio Mayor de Cundinamarca '
  },
  {
    code: '9936',
    name: 'Universidad Libre '
  },
  {
    code: '9936',
    name: 'Uniagraria '
  },
  {
    code: '9936',
    name: 'Universidad Militar Nueva Granada '
  },
  {
    code: '9936',
    name: 'Corporación Universitaria U de Colombia '
  },
  {
    code: '9936',
    name: 'Universidad UNAB '
  },
  {
    code: '9936',
    name: 'Instituto Francisco Galton '
  },
  {
    code: '9936',
    name: 'Instituto Francisco Galton '
  },
  {
    code: '9936',
    name: 'Institución Educativa Inandina '
  },
  {
    code: '9936',
    name: 'Instituto Malla '
  },
  {
    code: '9936',
    name: 'Corporación Educativa Sinfronteras'
  },
  {
    code: '9936',
    name: 'system center '
  },
  {
    code: '9936',
    name: 'Campoalto'
  },
  {
    code: '9936',
    name: 'Campoalto'
  },
  { code: '9110', name: 'SENA (Bogotá D.C.) SERVICIO NACIONAL DE APRENDIZAJE' },
  { code: '9111', name: 'SENA (Valle del Cauca) SERVICIO NACIONAL DE APRENDIZAJE' },
  { code: '9112', name: 'SENA (Antioquia) SERVICIO NACIONAL DE APRENDIZAJE' },
  { code: '9113', name: 'SENA (Cundinamarca) SERVICIO NACIONAL DE APRENDIZAJE' },
  { code: '9114', name: 'SENA (Quindío) SERVICIO NACIONAL DE APRENDIZAJE' },
  { code: '9115', name: 'SENA (Valle del Cauca) SERVICIO NACIONAL DE APRENDIZAJE' },
  {
    code: '9999',
    name: 'Otra Institución Educativa'
  }
]
