<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :placeholder="label"
    clearable
    :disabled="disabled"
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :label="item.serviceName" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import select, { model } from './common'

export default {
  extends: select('services', 'service'),
  props: {
    companyKey: { required: false },
    disabled: { required: false },
  },
  data() {
    return model()
  },
  watch: {
    companyKey(newValue) {
      this.$emit('input', '')
      if (newValue) {
        this.get(`findByCompany/${newValue}`)
      }
    },
  },
}
</script>
