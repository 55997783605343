<template>
  <el-container style="height: 100%">
    <el-row class="el-menu--horizontal el-menu">
      <el-col :lg="12">
        <el-header>
          <img
            class="header-logo"
            src="@/assets/img/svg/logo-aliaddo.svg"
            alt="Aliaddo"
            height="45"
          />
        </el-header>
      </el-col>
      <el-col :lg="12">
        <el-header class="service-account">
          <span v-cus-loading="loading.service" class="sub">
            {{ $tc('alreadyHasAccount') }}
            <el-button
              size="mini"
              type="primary"
              style="background-color: #582b86; border-color: #582b86"
              @click="onGoToService()"
              >{{ $tc('clickHere') }}</el-button>
          </span>
        </el-header>
      </el-col>
    </el-row>
    <!-- class="pattern-gradient-dark" -->
    <el-main style="height: 20%">
      <router-view />
    </el-main>
    <el-footer
      style="
        border-top: 1px solid #e6e6e6;
        background-color: #fafafa;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col
          :sm="12"
          style="text-align: center; font-size: 0.875rem; line-height: 1.71"
        >
          <el-link
            href="http://soporte.aliaddo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $tc('support') }}
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://aliaddo.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $tc('blog') }}
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://aliaddo.com/contacto"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $tc('contact') }}
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="http://estado.aliaddo.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $tc('serviceStatus') }}
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://www.facebook.com/aliaddo.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook"></i>
          </el-link>
          &nbsp;&nbsp;
          <el-link
            href="https://twitter.com/aliaddo_co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter"></i>
          </el-link>
        </el-col>
      </el-row>
    </el-footer>
    <el-footer
      style="
        border: solid 1px #e6e6e6;
        background-color: #f1f1f1;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col :sm="12" style="text-align: center; font-size: 12px">
          {{ $tc('copyright') }}
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'SignupLayout',
  data() {
    return {
      loading: {
        service: false
      },
      model: {
        service: {
          serviceDomain: ''
        }
      }
    }
  },
  computed: {
    crmParams: _this => {
      if (_this.$route.query.product) {
        return _this.$route.query.product.split('-')
      }

      return []
    },
    product: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    service: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    planName: _this => {
      if (_this.$route.query.plan) {
        return _this.$route.query.plan
      }
      if (_this.crmParams.length > 1) {
        return _this.crmParams[1].trim()
      }

      return ''
    },
    periodicidad: _this => {
      if (_this.$route.query.periodicidad) {
        return _this.$route.query.periodicidad
      }
      if (_this.crmParams.length > 2) {
        return _this.crmParams[2].trim()
      }

      return ''
    },
    serviceId: _this => {
      if (_this.product === 'fe') {
        return 1
      }
      if (_this.product === 'aliaddo') {
        return 2
      }
      if (_this.product === 'aliaddopos') {
        return 8
      }
      if (_this.product === 'isv') {
        return 7
      }
      if (_this.product === 'aliaddonominafull') {
        return 10
      }
      if (_this.product === 'aliaddonominalite') {
        return 11
      }
      if (_this.product === 'aliaddonominaisv') {
        return 12
      }
    }
  },
  created() {
    this.getService()
  },
  methods: {
    onGoToService() {
      window.open(`https://${this.model.service.serviceDomain}`, '_blank')
    },
    getService() {
      const _this = this

      _this.loading.service = true

      _this.$http
        .get(`services/${_this.serviceId}`)
        .then(response => (_this.model.service = response.data))
        .finally(_ => (_this.loading.service = false))
    }
  }
}
</script>
<style lang="scss">
main.el-main:first-child {
  padding: 0px;
}

.el-container .pattern-gradient-dark {
  background-image: url('../assets/img/background-cover.jpg'),
    linear-gradient(rgba(36, 150, 208, 0.9), rgb(208, 245, 255));
  background-size: 1440px, 100%;
  background-size: cover;
}

/* footer
-------------------------------------------------- */
.service-account {
  padding: 15px 20px;
  text-align: right;

  .sub {
    color: #aaa;
    text-align: right;
    margin: 20px 20px;
  }
}

/* End of footer
-------------------------------------------------- */
</style>
