export default [
  {
    key: 'PPN',
    value: 'Pasaporte',
  },
  {
    key: 'TAX',
    value: 'TAX',
  },
  {
    key: 'LIC',
    value: 'Labeler Identification Code',
  },
  {
    key: 'CC',
    value: 'CC  Cédula de ciudadanía',
  },
  {
    key: 'CE',
    value: 'Cédula de extranjería',
  },
  {
    key: 'TI',
    value: ' Tarjeta de identidad',
  },
  {
    key: 'NIT',
    value: 'Número de Identificación Tributaria',
  },
  {
    key: 'RUT',
    value: 'Registro único tributario',
  },
  {
    key: 'CI',
    value: ' Cédula de identidad',
  },
  {
    key: 'RUC',
    value: 'Registro Único de Contribuyentes',
  },
  {
    key: 'CIP',
    value: 'Cédula de identidad personal',
  },
  {
    key: 'CPF',
    value: 'Cadastro de Pessoas Físicas',
  },
]
