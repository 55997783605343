import { DateTime } from 'luxon'
import { i18n } from '../i18n'

export * from '@/helpers/string'
export * from './currency'

const culture = 'es-CO'
const currency = 'COP'
const timeZone = 'America/Bogota'
export function status(value) {
  if (value) {
    switch (value) {
      case 'active':
        return `<el-tag type="success">${i18n.t(value)}</el-tag>`
      case 'inactive':
        return `<el-tag type="danger">${i18n.t(value)}</el-tag>`
      default:
        return ''
    }
  }
}
export function capital(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
export function state(value) {
  if (value) {
    return i18n.t(value)
  }

  return '--'
}
export function formatToLocale(value) {
  if (value) {
    return i18n.t(value)
  }

  return '--'
}
export function formatToDate(value) {
  // var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  if (value && value.toString().indexOf('.') === -1 && parseInt(value.toString().indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(new Date(value).toISOString(), { zone: 'UTC' }).toISODate()

    // const date = new Date(value).toLocaleDateString(culture, {
    //   ...options,
    //   timeZoneId: timeZone
    // })
    // return date
  } if (value && value.toString().indexOf('.') !== -1 && parseInt(value.toString().indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(new Date(value * 1000).toISOString()).toISODate()

    // const date = new Date(value * 1000).toLocaleDateString(culture, {
    //   ...options,
    //   timeZoneId: timeZone
    // })
    // return date
  }

  return ''
}
export function formatToDateCO(value) {
  // var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  if (value && value.toString().indexOf('.') === -1 && parseInt(value.toString().indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(new Date(value).toISOString(), { zone: 'UTC' }).toFormat('dd-MMM-yyyy')
  } if (value && value.toString().indexOf('.') !== -1 && parseInt(value.toString().indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(new Date(value * 1000).toISOString()).toFormat('dd-MMM-yyyy')
  }

  return ''
}
export function formatToTime(value) {
  if (value && value !== '0001-01-01T00:00:00Z') {
    const date = new Date(value)

    return date.toLocaleTimeString(culture, {
      timeZoneId: timeZone,
    })
  }

  return ''
}

export function formatToDateTime(value) {
  if (value && value.toString().indexOf('.') === -1 && parseInt(value.indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(
      new Date(value).toISOString(),
    ).toFormat('yyyy-MM-dd t')

    // const date = new Date(value)
    // const _date = date.toLocaleDateString(culture, {
    //   timeZoneId: timeZone
    // })
    // const _time = date.toLocaleTimeString(culture, {
    //   timeZoneId: timeZone
    // })
    // return _date + ' ' + _time
  } if (value && value.toString().indexOf('.') !== -1 && parseInt(value.toString().indexOf('1/1/1')) === -1 && value !== '0001-01-01T00:00:00Z') {
    const date = new Date(value * 1000)
    const _date = date.toLocaleDateString(culture, {
      timeZoneId: timeZone,
    })
    const _time = date.toLocaleTimeString(culture, {
      timeZoneId: timeZone,
    })

    return `${_date} ${_time}`
  }

  return ''
}

export function formatToDecimal(value) {
  if (value) {
    return value.toLocaleString(culture)
  }
}

export function formatToMoney(value) {
  // if (value) {
  //   return value ? '$ ' + value.toLocaleString(culture, { minimumFractionDigits: 2 }) : '$ 0'
  // }
  // return '$ ' + 0
  return formatToCurrency(value)
}

export function formatToCurrency(value) {
  if (value) {
    return parseFloat(value).toLocaleString(culture, {
      currencyDisplay: 'symbol',
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    })
  }

  return (0).toLocaleString(culture, {
    currencyDisplay: 'symbol',
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  })
}

export function formatToPercent(value) {
  if (value) {
    return (parseFloat(value) / 100).toLocaleString(culture, {
      style: 'percent',
      minimumFractionDigits: 2,
    })
  }

  return (0).toLocaleString(culture, {
    style: 'percent',
    minimumFractionDigits: 2,
  })
}
