<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    filterable
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :value="item.id" :label="item.planName + ' (' + item.planMode + ')'"></el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('plans', 'plan'),
  props: {
    value: { required: true },
    serviceId: { required: true },
    initBlank: { required: false },
  },
  data() {
    return model()
  },
  watch: {
    serviceId(newValue) {
      if (newValue) {
        this.get(`findByService/${newValue}`)
      }
    },
  },
  mounted() {
    const _this = this
    if (_this.serviceId) {
      _this.$http.get(`plans/findByService/${_this.serviceId}`).then(response => {
        _this.options = response.body.filter(p => p.planMode !== 'paquetePeriodicidad')
      }, response => {

      })
    }
  },
}
</script>
