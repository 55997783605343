<template>
  <el-select
    v-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    clearable
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :value="item.id" :label="item.roleName"></el-option>
  </el-select>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('roles', 'role'),
  props: {
    companyKey: { required: false },
    serviceId: { required: false },
  },
  data() {
    return model()
  },
  watch: {
    companyKey() {
      this.$emit('input', '')
      this.getData()
    },
    serviceId() {
      this.$emit('input', '')
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const _this = this
      if (_this.companyKey && _this.serviceId !== '') {
        _this.get(`findByCompanyByService/${_this.companyKey}/${_this.serviceId}`)
      }
    },
  },
}
</script>
