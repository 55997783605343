<template>
  <el-select
    ref="select"
    :value="value"
    loading-text="Buscando..."
    clearable
    value-key="id"
    filterable
    remote
    placeholder="Minimo 3 caracteres..."
    :remote-method="search"
    :loading="loading"
    :automatic-dropdown="true"
    :selected-value="selectedValue"
    :find-by-value="findByValue"
    @clear="onClear"
    @change="onChange($event)"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.personFull"
      :value="item.personId"
    >
      <strong style="float: left;">{{ item.personFull }}</strong>
    </el-option>
    <!-- Texto que se muestra cuando no hay datos para mostrar y el botón crear -->
    <p slot="empty" class="el-select-dropdown__empty">
      <template>
        <div style="margin: auto;">
          <i class="el-icon-loading"></i>
          <span style="padding-left: 5px;" v-text="$t('loading')"></span>
        </div>
      </template>
    </p>
  </el-select>
</template>
<script>
import axios from 'axios'
import { responseException } from '@/assets/js/nbxr'

export default {
  props: {
    value: {},
    selectedValue: {},
  },
  data() {
    return {
      options: [],
      loading: false,
      findByValue: 'customer',
    }
  },
  watch: {
    selectedValue() {
      const _this = this
      if (_this.selectedValue) {
        setTimeout(() => {
          _this.loading = true
          _this.$http
            .get(
              `http://localhost:64667/api/v1/public/people/26fc9f6572f34d7ba6b0519f12f4232a/${_this.selectedValue}`,
            )
            .then(
              response => {
                if (response.body) {
                  _this.options = []
                  _this.options.push(response.body)
                  _this.$emit('input', response.body.id)
                  _this.$emit('set-person', response.body)
                }
                _this.loading = false
              },
              response => {
                _this.loading = false
                _this.$message.error(responseException(response))
              },
            )
        }, 500)
      }
    },
  },
  methods: {
    search(query) {
      const _this = this
      if (query !== '' && query.length && query.length >= 3) {
        _this.loading = true
        setTimeout(() => {
          axios
            .get(
              `https://app.aliaddo.com/api/v1/public/people/26fc9f6572f34d7ba6b0519f12f4232a/search?term=${query}&group=${_this.findByValue}`,
              {
                method: 'GET',
                mode: 'no-cors',
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'same-origin',
              },
            )
            .then(response => {
              // handle success
              // console.log(response)
              _this.loading = false
            })
            .catch(e => {
              _this.loading = false
              _this.$message.error(responseException(e))

              // console.log(e)
            })
        }, 2000)
      } else {
        _this.options = []
      }
    },
    onChange(selectedId) {
      const _this = this

      if (selectedId) {
        _this.$emit('input', selectedId)
        _this.$emit(
          'set-person',
          _this.options.find(person => person.id === selectedId),
        )
      }
    },
    onClear() {
      const _this = this
      _this.$emit('set-person', '')
      _this.$emit('input', '')
    },
  },
}
</script>
