<template>
  <el-select
    :value="value"
    :clearable="clearable"
    :placeholder="$tc('typeForSearchDepartament')"
    :remote-method="onLocalSearch"
    filterable
    remote
    reserve-keyword
    @change="onChange"
  >
    <el-option v-for="(item, index) in data" :key="index" :value="item.code" :label="item.name">
      <span style="float: left">{{ item.name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">({{ item.iso }})-{{ item.code }}</span>
    </el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'
import data from '@/assets/js/data/CO/regions'

export default {
  extends: select('', 'region', { attributeLabel: 'name', attributeValue: 'code', data }),
  data() {
    return model()
  },
  watch: {
    value(newValue) {
      this.setLocalInitValue(newValue)
    },
  },
  mounted() {
    this.setLocalInitValue(this.value)
  },
}
</script>
