export default [
  {
    code: '05002', name: 'Abejorral (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '54003', name: 'Abrego (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05004', name: 'Abriaquí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '50006', name: 'Acacías (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '27006', name: 'Acandí (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '41006', name: 'Acevedo (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '13006', name: 'Achí (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '41013', name: 'Agrado (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25001', name: 'Agua de Dios (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '20011', name: 'Aguachica (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '68013', name: 'Aguada (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '17013', name: 'Aguadas (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '85010', name: 'Aguazul (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '20013', name: 'Agustín Codazzi (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '41016', name: 'Aipe (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25019', name: 'Albán (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52019', name: 'Albán (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '18029', name: 'Albania (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '44035', name: 'Albania (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '68020', name: 'Albania (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '76020', name: 'Alcalá (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '52022', name: 'Aldana (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05021', name: 'Alejandría (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '47030', name: 'Algarrobo (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '41020', name: 'Algeciras (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '19022', name: 'Almaguer (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '15022', name: 'Almeida (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '73024', name: 'Alpujarra (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '41026', name: 'Altamira (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '27025', name: 'Alto Baudo (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '13030', name: 'Altos del Rosario (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '73026', name: 'Alvarado (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05030', name: 'Amagá (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05031', name: 'Amalfi (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73030', name: 'Ambalema (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '25035', name: 'Anapoima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52036', name: 'Ancuyá (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76036', name: 'Andalucía (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05034', name: 'Andes (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05036', name: 'Angelópolis (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05038', name: 'Angostura (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25040', name: 'Anolaima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05040', name: 'Anorí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '17042', name: 'Anserma (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '76041', name: 'Ansermanuevo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05044', name: 'Anza (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73043', name: 'Anzoátegui (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05045', name: 'Apartadó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '66045', name: 'Apía (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '25599', name: 'Apulo (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15047', name: 'Aquitania (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '47053', name: 'Aracataca (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '17050', name: 'Aranzazu (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '68051', name: 'Aratoca (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '81001', name: 'Arauca (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '81065', name: 'Arauquita (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '25053', name: 'Arbeláez (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52051', name: 'Arboleda (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '54051', name: 'Arboledas (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05051', name: 'Arboletes (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15051', name: 'Arcabuco (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '13042', name: 'Arenal (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05055', name: 'Argelia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '19050', name: 'Argelia (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '76054', name: 'Argelia (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '47058', name: 'Ariguaní (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '13052', name: 'Arjona (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05059', name: 'Armenia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '63001', name: 'Armenia (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '73055', name: 'Armero (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '13062', name: 'Arroyohondo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '20032', name: 'Astrea (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '73067', name: 'Ataco (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '27050', name: 'Atrato (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '23068', name: 'Ayapel (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '27073', name: 'Bagadó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27075', name: 'Bahía Solano (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27077', name: 'Bajo Baudó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '19075', name: 'Balboa (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '66075', name: 'Balboa (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '08078', name: 'Baranoa (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '41078', name: 'Baraya (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '52079', name: 'Barbacoas (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05079', name: 'Barbosa (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68077', name: 'Barbosa (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68079', name: 'Barichara (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '50110', name: 'Barranca de Upía (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '68081', name: 'Barrancabermeja (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '44078', name: 'Barrancas (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '13074', name: 'Barranco de Loba (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '94343', name: 'Barranco Minas (GUA)', regionCode: '94', countryCode: 'CO',
  },
  {
    code: '08001', name: 'Barranquilla (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '20045', name: 'Becerril (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '17088', name: 'Belalcázar (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '15087', name: 'Belén (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '52083', name: 'Belén (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '27086', name: 'Belén de Bajirá (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '18094', name: 'Belén de los Andaquies (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '66088', name: 'Belén de Umbría (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '05088', name: 'Bello (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05086', name: 'Belmira (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25086', name: 'Beltrán (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15090', name: 'Berbeo (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05091', name: 'Betania (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15092', name: 'Betéitiva (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05093', name: 'Betulia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68092', name: 'Betulia (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25095', name: 'Bituima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15097', name: 'Boavita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '54099', name: 'Bochalema (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '11001', name: 'Bogotá, D.C. (BOG)', regionCode: '11', countryCode: 'CO',
  },
  {
    code: '25099', name: 'Bojacá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27099', name: 'Bojaya (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '19100', name: 'Bolívar (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68101', name: 'Bolívar (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '76100', name: 'Bolívar (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '20060', name: 'Bosconia (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '15104', name: 'Boyacá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05107', name: 'Briceño (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15106', name: 'Briceño (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68001', name: 'Bucaramanga (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '54109', name: 'Bucarasica (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '76109', name: 'Buenaventura (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15109', name: 'Buenavista (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '23079', name: 'Buenavista (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '63111', name: 'Buenavista (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '70110', name: 'Buenavista (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '19110', name: 'Buenos Aires (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '52110', name: 'Buesaco (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76113', name: 'Bugalagrande (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05113', name: 'Buriticá (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15114', name: 'Busbanzá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25120', name: 'Cabrera (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68121', name: 'Cabrera (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '50124', name: 'Cabuyaro (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '05120', name: 'Cáceres (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25123', name: 'Cachipay (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54128', name: 'Cachirá (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '54125', name: 'Cácota (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05125', name: 'Caicedo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '76122', name: 'Caicedonia (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '70124', name: 'Caimito (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '73124', name: 'Cajamarca (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '19130', name: 'Cajibío (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '25126', name: 'Cajicá  (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '13140', name: 'Calamar (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '95015', name: 'Calamar (GUV)', regionCode: '95', countryCode: 'CO',
  },
  {
    code: '63130', name: 'Calarca (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '05129', name: 'Caldas (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15131', name: 'Caldas (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19137', name: 'Caldono (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '76001', name: 'Cali (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '68132', name: 'California (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '76126', name: 'Calima (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '19142', name: 'Caloto (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '05134', name: 'Campamento (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '08137', name: 'Campo de la Cruz (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '41132', name: 'Campoalegre (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '15135', name: 'Campohermoso (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '23090', name: 'Canalete (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '05138', name: 'Cañasgordas (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '08141', name: 'Candelaria (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '76130', name: 'Candelaria (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '13160', name: 'Cantagallo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '25148', name: 'Caparrapí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68147', name: 'Capitanejo (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25151', name: 'Caqueza (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05142', name: 'Caracolí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05145', name: 'Caramanta (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68152', name: 'Carcasí (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05147', name: 'Carepa (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73148', name: 'Carmen de Apicalá (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '25154', name: 'Carmen de Carupa (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27150', name: 'Carmen del Darien (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '05150', name: 'Carolina (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '13001', name: 'Cartagena (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '18150', name: 'Cartagena del Chairá (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '76147', name: 'Cartago (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '97161', name: 'Caruru (VAU)', regionCode: '97', countryCode: 'CO',
  },
  {
    code: '73152', name: 'Casabianca (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '50150', name: 'Castilla la Nueva (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '05154', name: 'Caucasia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68160', name: 'Cepitá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '23162', name: 'Cereté (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '15162', name: 'Cerinza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68162', name: 'Cerrito (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '47161', name: 'Cerro San Antonio (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '27160', name: 'Cértegui (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '52240', name: 'Chachagüí (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '25168', name: 'Chaguaní (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '70230', name: 'Chalán (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '85015', name: 'Chameza (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '73168', name: 'Chaparral (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '68167', name: 'Charalá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68169', name: 'Charta (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25175', name: 'Chía (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '47170', name: 'Chibolo (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '05172', name: 'Chigorodó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '23168', name: 'Chimá (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '68176', name: 'Chima (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '20175', name: 'Chimichagua (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '54172', name: 'Chinácota (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15172', name: 'Chinavita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '17174', name: 'Chinchiná (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '23182', name: 'Chinú (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '25178', name: 'Chipaque (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68179', name: 'Chipatá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15176', name: 'Chiquinquirá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15232', name: 'Chíquiza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '20178', name: 'Chiriguaná (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '15180', name: 'Chiscas (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15183', name: 'Chita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '54174', name: 'Chitagá (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15185', name: 'Chitaraque (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15187', name: 'Chivatá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15236', name: 'Chivor (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25181', name: 'Choachí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25183', name: 'Chocontá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '13188', name: 'Cicuco (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '47189', name: 'Ciénaga (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '23189', name: 'Ciénaga de Oro (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '15189', name: 'Ciénega (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68190', name: 'Cimitarra (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '63190', name: 'Circasia (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '05190', name: 'Cisneros (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05101', name: 'Ciudad Bolívar (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '13222', name: 'Clemencia (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05197', name: 'Cocorná (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73200', name: 'Coello (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '25200', name: 'Cogua (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '41206', name: 'Colombia (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '52203', name: 'Colón (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '86219', name: 'Colón (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '70204', name: 'Coloso (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '15204', name: 'Cómbita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05206', name: 'Concepción (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68207', name: 'Concepción (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05209', name: 'Concordia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '47205', name: 'Concordia (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '27205', name: 'Condoto (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '68209', name: 'Confines (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '52207', name: 'Consaca (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '52210', name: 'Contadero (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '68211', name: 'Contratación (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '54206', name: 'Convención (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05212', name: 'Copacabana (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15212', name: 'Coper (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '13212', name: 'Córdoba (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '52215', name: 'Córdoba (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '63212', name: 'Córdoba (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '19212', name: 'Corinto (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68217', name: 'Coromoro (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '70215', name: 'Corozal (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '15215', name: 'Corrales (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25214', name: 'Cota (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '23300', name: 'Cotorra (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '15218', name: 'Covarachía (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '70221', name: 'Coveñas (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '73217', name: 'Coyaima (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '81220', name: 'Cravo Norte (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '52224', name: 'Cuaspud (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '15223', name: 'Cubará (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '50223', name: 'Cubarral (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '15224', name: 'Cucaita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25224', name: 'Cucunubá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54001', name: 'Cúcuta (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '54223', name: 'Cucutilla (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15226', name: 'Cuítiva (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '50226', name: 'Cumaral (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '99773', name: 'Cumaribo (VIC)', regionCode: '99', countryCode: 'CO',
  },
  {
    code: '52227', name: 'Cumbal (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '52233', name: 'Cumbitara (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '73226', name: 'Cunday (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '18205', name: 'Curillo (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '68229', name: 'Curití (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '20228', name: 'Curumaní (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '05234', name: 'Dabeiba (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '76233', name: 'Dagua (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '44090', name: 'Dibulla (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '44098', name: 'Distracción (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '73236', name: 'Dolores (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05237', name: 'Don Matías (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '66170', name: 'Dosquebradas (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '15238', name: 'Duitama (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '54239', name: 'Durania (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05240', name: 'Ebéjico (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '76243', name: 'El Águila (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05250', name: 'El Bagre (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '47245', name: 'El Banco (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '76246', name: 'El Cairo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '50245', name: 'El Calvario (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '27135', name: 'El Cantón del San Pablo (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '54245', name: 'El Carmen (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '27245', name: 'El Carmen de Atrato (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '13244', name: 'El Carmen de Bolívar (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '68235', name: 'El Carmen de Chucurí (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05148', name: 'El Carmen de Viboral (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '50251', name: 'El Castillo (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '76248', name: 'El Cerrito (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '52250', name: 'El Charco (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '15244', name: 'El Cocuy (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25245', name: 'El Colegio (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '20238', name: 'El Copey (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '18247', name: 'El Doncello (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '50270', name: 'El Dorado (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '76250', name: 'El Dovio (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '91263', name: 'El Encanto (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '15248', name: 'El Espino (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68245', name: 'El Guacamayo (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '13248', name: 'El Guamo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '27250', name: 'El Litoral del San Juan (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '44110', name: 'El Molino (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '20250', name: 'El Paso (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '18256', name: 'El Paujil (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '52254', name: 'El Peñol (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '13268', name: 'El Peñón (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '25258', name: 'El Peñón (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68250', name: 'El Peñón (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '47258', name: 'El Piñon (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '68255', name: 'El Playón (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '47268', name: 'El Retén (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '95025', name: 'El Retorno (GUV)', regionCode: '95', countryCode: 'CO',
  },
  {
    code: '70233', name: 'El Roble (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25260', name: 'El Rosal (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52256', name: 'El Rosario (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05697', name: 'El Santuario (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52258', name: 'El Tablón de Gómez (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '19256', name: 'El Tambo (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '52260', name: 'El Tambo (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '54250', name: 'El Tarra (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '54261', name: 'El Zulia (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '41244', name: 'Elías (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '68264', name: 'Encino (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68266', name: 'Enciso (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05264', name: 'Entrerrios (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05266', name: 'Envigado (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73268', name: 'Espinal (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '25269', name: 'Facatativá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '73270', name: 'Falan (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '17272', name: 'Filadelfia (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '63272', name: 'Filandia (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '15272', name: 'Firavitoba (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '73275', name: 'Flandes (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '18001', name: 'Florencia (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '19290', name: 'Florencia (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '15276', name: 'Floresta (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68271', name: 'Florián (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '76275', name: 'Florida (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '68276', name: 'Floridablanca (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25279', name: 'Fomeque (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '44279', name: 'Fonseca (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '81300', name: 'Fortul (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '25281', name: 'Fosca (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52520', name: 'Francisco Pizarro (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05282', name: 'Fredonia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73283', name: 'Fresno (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05284', name: 'Frontino (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '50287', name: 'Fuente de Oro (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '47288', name: 'Fundación (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '52287', name: 'Funes (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '25286', name: 'Funza (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25288', name: 'Fúquene (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25290', name: 'Fusagasugá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25293', name: 'Gachala (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25295', name: 'Gachancipá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15293', name: 'Gachantivá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25297', name: 'Gachetá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68296', name: 'Galán (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08296', name: 'Galapa (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '70235', name: 'Galeras (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25299', name: 'Gama (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '20295', name: 'Gamarra (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '68298', name: 'Gambita (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15296', name: 'Gameza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15299', name: 'Garagoa (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '41298', name: 'Garzón (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '63302', name: 'Génova (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '41306', name: 'Gigante (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '76306', name: 'Ginebra (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05306', name: 'Giraldo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25307', name: 'Girardot (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05308', name: 'Girardota (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68307', name: 'Girón (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05310', name: 'Gómez Plata (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '20310', name: 'González (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '54313', name: 'Gramalote (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05313', name: 'Granada (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25312', name: 'Granada (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '50313', name: 'Granada (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '68318', name: 'Guaca (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15317', name: 'Guacamayas (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '76318', name: 'Guacarí (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '19300', name: 'Guachené (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '25317', name: 'Guachetá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52317', name: 'Guachucal (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76111', name: 'Guadalajara de Buga (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05315', name: 'Guadalupe (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '41319', name: 'Guadalupe (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '68320', name: 'Guadalupe (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25320', name: 'Guaduas (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52320', name: 'Guaitarilla (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '52323', name: 'Gualmatán (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '47318', name: 'Guamal (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '50318', name: 'Guamal (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '73319', name: 'Guamo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '19318', name: 'Guapi (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68322', name: 'Guapotá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '70265', name: 'Guaranda (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '05318', name: 'Guarne (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25322', name: 'Guasca (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05321', name: 'Guatape (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25324', name: 'Guataquí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25326', name: 'Guatavita (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15322', name: 'Guateque (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '66318', name: 'Guática (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '68324', name: 'Guavatá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25328', name: 'Guayabal de Siquima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25335', name: 'Guayabetal (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15325', name: 'Guayatá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68327', name: 'Güepsa (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15332', name: 'Güicán (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25339', name: 'Gutiérrez (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54344', name: 'Hacarí (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '13300', name: 'Hatillo de Loba (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '68344', name: 'Hato (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '85125', name: 'Hato Corozal (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '44378', name: 'Hatonuevo (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '05347', name: 'Heliconia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '54347', name: 'Herrán (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '73347', name: 'Herveo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05353', name: 'Hispania (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '41349', name: 'Hobo (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '73349', name: 'Honda (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '73001', name: 'Ibagué (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '73352', name: 'Icononzo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '52352', name: 'Iles (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '52354', name: 'Imués (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '94001', name: 'Inírida (GUA)', regionCode: '94', countryCode: 'CO',
  },
  {
    code: '19355', name: 'Inzá (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '52356', name: 'Ipiales (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '41357', name: 'Iquira (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '41359', name: 'Isnos (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '27361', name: 'Istmina (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '05360', name: 'Itagui (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05361', name: 'Ituango (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15362', name: 'Iza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19364', name: 'Jambaló (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '76364', name: 'Jamundí (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05364', name: 'Jardín (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15367', name: 'Jenesano (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05368', name: 'Jericó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15368', name: 'Jericó (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25368', name: 'Jerusalén (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68368', name: 'Jesús María (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68370', name: 'Jordán (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08372', name: 'Juan de Acosta (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '25372', name: 'Junín (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27372', name: 'Juradó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '23350', name: 'La Apartada (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '41378', name: 'La Argentina (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '68377', name: 'La Belleza (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25377', name: 'La Calera (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15380', name: 'La Capilla (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05376', name: 'La Ceja (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '66383', name: 'La Celia (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '91405', name: 'La Chorrera (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '52378', name: 'La Cruz (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76377', name: 'La Cumbre (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '17380', name: 'La Dorada (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '54385', name: 'La Esperanza (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05380', name: 'La Estrella (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52381', name: 'La Florida (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '20383', name: 'La Gloria (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '20400', name: 'La Jagua de Ibirico (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '44420', name: 'La Jagua del Pilar (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '52385', name: 'La Llanada (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '50350', name: 'La Macarena (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '17388', name: 'La Merced (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '25386', name: 'La Mesa (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '18410', name: 'La Montañita (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '25394', name: 'La Palma (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '20621', name: 'La Paz (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '68397', name: 'La Paz (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '91407', name: 'La Pedrera (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '25398', name: 'La Peña (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05390', name: 'La Pintada (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '41396', name: 'La Plata (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '54398', name: 'La Playa (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '99524', name: 'La Primavera (VIC)', regionCode: '99', countryCode: 'CO',
  },
  {
    code: '85136', name: 'La Salina (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '19392', name: 'La Sierra (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '63401', name: 'La Tebaida (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '52390', name: 'La Tola (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05400', name: 'La Unión (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52399', name: 'La Unión (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '70400', name: 'La Unión (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '76400', name: 'La Unión (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15403', name: 'La Uvita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19397', name: 'La Vega (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '25402', name: 'La Vega (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '91430', name: 'La Victoria (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '15401', name: 'La Victoria (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '76403', name: 'La Victoria (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '66400', name: 'La Virginia (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '54377', name: 'Labateca (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15377', name: 'Labranzagrande (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68385', name: 'Landázuri (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68406', name: 'Lebríja (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '86573', name: 'Leguízamo (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '52405', name: 'Leiva (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '50400', name: 'Lejanías (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '25407', name: 'Lenguazaque (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '73408', name: 'Lérida (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '91001', name: 'Leticia (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '73411', name: 'Líbano (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05411', name: 'Liborina (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52411', name: 'Linares (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '27413', name: 'Lloró (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '19418', name: 'López (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '23417', name: 'Lorica (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '52418', name: 'Los Andes (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '23419', name: 'Los Córdobas (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '70418', name: 'Los Palmitos (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '54405', name: 'Los Patios (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '68418', name: 'Los Santos (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '54418', name: 'Lourdes (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '08421', name: 'Luruaco (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '15425', name: 'Macanal (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68425', name: 'Macaravita (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05425', name: 'Maceo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25426', name: 'Macheta (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25430', name: 'Madrid (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '13430', name: 'Magangué (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '52427', name: 'Magüi (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '13433', name: 'Mahates (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '44430', name: 'Maicao (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '70429', name: 'Majagual (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '68432', name: 'Málaga (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08433', name: 'Malambo (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '52435', name: 'Mallama (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '08436', name: 'Manatí (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '20443', name: 'Manaure (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '44560', name: 'Manaure (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '85139', name: 'Maní (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '17001', name: 'Manizales (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '25436', name: 'Manta (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '17433', name: 'Manzanares (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '50325', name: 'Mapiripán (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '94663', name: 'Mapiripana (GUA)', regionCode: '94', countryCode: 'CO',
  },
  {
    code: '13440', name: 'Margarita (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '13442', name: 'María la Baja (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05440', name: 'Marinilla (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15442', name: 'Maripí (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '73443', name: 'Mariquita (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '17442', name: 'Marmato (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '17444', name: 'Marquetalia (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '66440', name: 'Marsella (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '17446', name: 'Marulanda (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '68444', name: 'Matanza (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '05001', name: 'Medellín (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25438', name: 'Medina (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27425', name: 'Medio Atrato (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27430', name: 'Medio Baudó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27450', name: 'Medio San Juan (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '73449', name: 'Melgar (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '19450', name: 'Mercaderes (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '50330', name: 'Mesetas (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '18460', name: 'Milán (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '15455', name: 'Miraflores (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '95200', name: 'Miraflores (GUV)', regionCode: '95', countryCode: 'CO',
  },
  {
    code: '19455', name: 'Miranda (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '91460', name: 'Miriti - Paraná (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '66456', name: 'Mistrató (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '97001', name: 'Mitú (VAU)', regionCode: '97', countryCode: 'CO',
  },
  {
    code: '86001', name: 'Mocoa (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '68464', name: 'Mogotes (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '68468', name: 'Molagavita (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '23464', name: 'Momil (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '13468', name: 'Mompós (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '15464', name: 'Mongua (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15466', name: 'Monguí (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15469', name: 'Moniquirá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '23500', name: 'Moñitos (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '05467', name: 'Montebello (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '13458', name: 'Montecristo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '23466', name: 'Montelíbano (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '63470', name: 'Montenegro (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '23001', name: 'Montería (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '85162', name: 'Monterrey (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '13473', name: 'Morales (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '19473', name: 'Morales (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '18479', name: 'Morelia (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '70473', name: 'Morroa (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25473', name: 'Mosquera (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52473', name: 'Mosquera (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '15476', name: 'Motavita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '73461', name: 'Murillo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05475', name: 'Murindó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05480', name: 'Mutatá (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '54480', name: 'Mutiscua (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15480', name: 'Muzo (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05483', name: 'Nariño (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25483', name: 'Nariño (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52480', name: 'Nariño (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '41483', name: 'Nátaga (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '73483', name: 'Natagaima (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05495', name: 'Nechí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05490', name: 'Necoclí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '17486', name: 'Neira (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '41001', name: 'Neiva (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25486', name: 'Nemocón (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25488', name: 'Nilo (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25489', name: 'Nimaima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15491', name: 'Nobsa (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25491', name: 'Nocaima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '17495', name: 'Norcasia (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '13490', name: 'Norosí (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '27491', name: 'Nóvita (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '47460', name: 'Nueva Granada (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '15494', name: 'Nuevo Colón (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85225', name: 'Nunchía (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '27495', name: 'Nuquí (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '76497', name: 'Obando (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '68498', name: 'Ocamonte (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '54498', name: 'Ocaña (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '68500', name: 'Oiba (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15500', name: 'Oicatá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05501', name: 'Olaya (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52490', name: 'Olaya Herrera (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '68502', name: 'Onzaga (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '41503', name: 'Oporapa (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '86320', name: 'Orito (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '85230', name: 'Orocué (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '73504', name: 'Ortega (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '52506', name: 'Ospina (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '15507', name: 'Otanche (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '70508', name: 'Ovejas (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '15511', name: 'Pachavita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25513', name: 'Pacho (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '17513', name: 'Pácora (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '19513', name: 'Padilla (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '15514', name: 'Páez (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19517', name: 'Paez (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '41518', name: 'Paicol (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '20517', name: 'Pailitas (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '25518', name: 'Paime (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15516', name: 'Paipa (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15518', name: 'Pajarito (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '41524', name: 'Palermo (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '17524', name: 'Palestina (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '41530', name: 'Palestina (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '68522', name: 'Palmar (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08520', name: 'Palmar de Varela (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '68524', name: 'Palmas del Socorro (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '76520', name: 'Palmira (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '70523', name: 'Palmito (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '73520', name: 'Palocabildo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '54518', name: 'Pamplona (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '54520', name: 'Pamplonita (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '25524', name: 'Pandi (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15522', name: 'Panqueba (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68533', name: 'Páramo (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25530', name: 'Paratebueno (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25535', name: 'Pasca (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52001', name: 'Pasto (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '19532', name: 'Patía (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '15531', name: 'Pauna (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15533', name: 'Paya (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85250', name: 'Paz de Ariporo (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '15537', name: 'Paz de Río (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '47541', name: 'Pedraza (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '20550', name: 'Pelaya (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '05541', name: 'Peñol (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '17541', name: 'Pensilvania (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '05543', name: 'Peque (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '66001', name: 'Pereira (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '15542', name: 'Pesca (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19533', name: 'Piamonte (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68547', name: 'Piedecuesta (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '73547', name: 'Piedras (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '19548', name: 'Piendamó (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '63548', name: 'Pijao (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '47545', name: 'Pijiño del Carmen (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '68549', name: 'Pinchote (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '13549', name: 'Pinillos (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '08549', name: 'Piojó (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '15550', name: 'Pisba (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '41548', name: 'Pital (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '41551', name: 'Pitalito (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '47551', name: 'Pivijay (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '73555', name: 'Planadas (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '23555', name: 'Planeta Rica (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '47555', name: 'Plato (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '52540', name: 'Policarpa (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '08558', name: 'Polonuevo (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '08560', name: 'Ponedera (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '19001', name: 'Popayán (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '85263', name: 'Pore (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '52560', name: 'Potosí (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76563', name: 'Pradera (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '73563', name: 'Prado (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '52565', name: 'Providencia (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '88564', name: 'Providencia (SAP)', regionCode: '88', countryCode: 'CO',
  },
  {
    code: '20570', name: 'Pueblo Bello (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '23570', name: 'Pueblo Nuevo (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '66572', name: 'Pueblo Rico (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '05576', name: 'Pueblorrico (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '47570', name: 'Puebloviejo (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '68572', name: 'Puente Nacional (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '52573', name: 'Puerres (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '91530', name: 'Puerto Alegría (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '91536', name: 'Puerto Arica (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '86568', name: 'Puerto Asís (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '05579', name: 'Puerto Berrío (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15572', name: 'Puerto Boyacá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '86569', name: 'Puerto Caicedo (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '99001', name: 'Puerto Carreño (VIC)', regionCode: '99', countryCode: 'CO',
  },
  {
    code: '08573', name: 'Puerto Colombia (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '50450', name: 'Puerto Concordia (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '23574', name: 'Puerto Escondido (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '50568', name: 'Puerto Gaitán (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '86571', name: 'Puerto Guzmán (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '23580', name: 'Puerto Libertador (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '50577', name: 'Puerto Lleras (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '50573', name: 'Puerto López (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '05585', name: 'Puerto Nare (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '91540', name: 'Puerto Nariño (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '68573', name: 'Puerto Parra (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '18592', name: 'Puerto Rico (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '50590', name: 'Puerto Rico (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '81591', name: 'Puerto Rondón (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '25572', name: 'Puerto Salgar (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '91669', name: 'Puerto Santander (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '54553', name: 'Puerto Santander (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '19573', name: 'Puerto Tejada (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '05591', name: 'Puerto Triunfo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68575', name: 'Puerto Wilches (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25580', name: 'Pulí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52585', name: 'Pupiales (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '19585', name: 'Puracé (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '73585', name: 'Purificación (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '23586', name: 'Purísima (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '25592', name: 'Quebradanegra (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25594', name: 'Quetame (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27001', name: 'Quibdó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '63594', name: 'Quimbaya (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '66594', name: 'Quinchía (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '15580', name: 'Quípama (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25596', name: 'Quipile (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54599', name: 'Ragonvalia (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15599', name: 'Ramiriquí (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15600', name: 'Ráquira (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85279', name: 'Recetor (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '13580', name: 'Regidor (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05604', name: 'Remedios (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '47605', name: 'Remolino (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '08606', name: 'Repelón (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '50606', name: 'Restrepo (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '76606', name: 'Restrepo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '05607', name: 'Retiro (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25612', name: 'Ricaurte (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52612', name: 'Ricaurte (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '20614', name: 'Río de Oro (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '27580', name: 'Río Iro (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27600', name: 'Río Quito (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '13600', name: 'Río Viejo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '73616', name: 'Rioblanco (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '76616', name: 'Riofrío (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '44001', name: 'Riohacha (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '05615', name: 'Rionegro (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68615', name: 'Rionegro (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '17614', name: 'Riosucio (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '27615', name: 'Riosucio (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '17616', name: 'Risaralda (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '41615', name: 'Rivera (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '52621', name: 'Roberto Payán (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '76622', name: 'Roldanillo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '73622', name: 'Roncesvalles (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '15621', name: 'Rondón (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19622', name: 'Rosas (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '73624', name: 'Rovira (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '68655', name: 'Sabana de Torres (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08634', name: 'Sabanagrande (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '05628', name: 'Sabanalarga (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '08638', name: 'Sabanalarga (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '85300', name: 'Sabanalarga (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '47660', name: 'Sabanas de San Angel (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '05631', name: 'Sabaneta (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15632', name: 'Saboyá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85315', name: 'Sácama (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '15638', name: 'Sáchica (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '23660', name: 'Sahagún (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '41660', name: 'Saladoblanco (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '17653', name: 'Salamina (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '47675', name: 'Salamina (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '54660', name: 'Salazar (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '73671', name: 'Saldaña (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '63690', name: 'Salento (QUI)', regionCode: '63', countryCode: 'CO',
  },
  {
    code: '05642', name: 'Salgar (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15646', name: 'Samacá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '17662', name: 'Samaná (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '52678', name: 'Samaniego (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '70670', name: 'Sampués (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '41668', name: 'San Agustín (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '20710', name: 'San Alberto (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '68669', name: 'San Andrés (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '88001', name: 'San Andrés (SAP)', regionCode: '88', countryCode: 'CO',
  },
  {
    code: '05647', name: 'San Andrés de Cuerquía (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52835', name: 'San Andres de Tumaco (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '23670', name: 'San Andrés Sotavento (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '23672', name: 'San Antero (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '73675', name: 'San Antonio (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '25645', name: 'San Antonio del Tequendama (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '68673', name: 'San Benito (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '70678', name: 'San Benito Abad (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25649', name: 'San Bernardo (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52685', name: 'San Bernardo (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '23675', name: 'San Bernardo del Viento (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '54670', name: 'San Calixto (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '05649', name: 'San Carlos (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '23678', name: 'San Carlos (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '50680', name: 'San Carlos de Guaroa (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '25653', name: 'San Cayetano (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54673', name: 'San Cayetano (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '13620', name: 'San Cristóbal (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '20750', name: 'San Diego (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '15660', name: 'San Eduardo (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '13647', name: 'San Estanislao (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '13650', name: 'San Fernando (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05652', name: 'San Francisco (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25658', name: 'San Francisco (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '86755', name: 'San Francisco (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '68679', name: 'San Gil (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '13654', name: 'San Jacinto (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '13655', name: 'San Jacinto del Cauca (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05656', name: 'San Jerónimo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68682', name: 'San Joaquín (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '17665', name: 'San José (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '05658', name: 'San José de la Montaña (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68684', name: 'San José de Miranda (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15664', name: 'San José de Pare (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '23682', name: 'San José de Uré (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '18610', name: 'San José del Fragua (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '95001', name: 'San José del Guaviare (GUV)', regionCode: '95', countryCode: 'CO',
  },
  {
    code: '27660', name: 'San José del Palmar (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '50683', name: 'San Juan de Arama (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '70702', name: 'San Juan de Betulia (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25662', name: 'San Juan de Río Seco (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05659', name: 'San Juan de Urabá (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '44650', name: 'San Juan del Cesar (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '13657', name: 'San Juan Nepomuceno (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '50686', name: 'San Juanito (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '52687', name: 'San Lorenzo (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05660', name: 'San Luis (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '73678', name: 'San Luis (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '15667', name: 'San Luis de Gaceno (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85325', name: 'San Luis de Palenque (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '70742', name: 'San Luis de Sincé (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '70708', name: 'San Marcos (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '20770', name: 'San Martín (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '50689', name: 'San Martín (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '13667', name: 'San Martín de Loba (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '15673', name: 'San Mateo (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '86757', name: 'San Miguel (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '68686', name: 'San Miguel (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15676', name: 'San Miguel de Sema (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '70713', name: 'San Onofre (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '13670', name: 'San Pablo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '52693', name: 'San Pablo (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '15681', name: 'San Pablo de Borbur (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05664', name: 'San Pedro (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '70717', name: 'San Pedro (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '76670', name: 'San Pedro (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '52694', name: 'San Pedro de Cartago (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05665', name: 'San Pedro de Uraba (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '23686', name: 'San Pelayo (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '05667', name: 'San Rafael (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05670', name: 'San Roque (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '19693', name: 'San Sebastián (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '47692', name: 'San Sebastián de Buenavista (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '05674', name: 'San Vicente (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68689', name: 'San Vicente de Chucurí (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '18753', name: 'San Vicente del Caguán (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '47703', name: 'San Zenón (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '52683', name: 'Sandoná (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '47707', name: 'Santa Ana (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '05679', name: 'Santa Bárbara (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52696', name: 'Santa Bárbara (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '68705', name: 'Santa Bárbara (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '47720', name: 'Santa Bárbara de Pinto (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '13673', name: 'Santa Catalina (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '68720', name: 'Santa Helena del Opón (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '73686', name: 'Santa Isabel (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '08675', name: 'Santa Lucía (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '15690', name: 'Santa María (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '41676', name: 'Santa María (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '47001', name: 'Santa Marta (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '13683', name: 'Santa Rosa (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '19701', name: 'Santa Rosa (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '66682', name: 'Santa Rosa de Cabal (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '05686', name: 'Santa Rosa de Osos (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15693', name: 'Santa Rosa de Viterbo (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '13688', name: 'Santa Rosa del Sur (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '99624', name: 'Santa Rosalía (VIC)', regionCode: '99', countryCode: 'CO',
  },
  {
    code: '15696', name: 'Santa Sofía (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '52699', name: 'Santacruz (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '05042', name: 'Santafé de Antioquia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15686', name: 'Santana (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19698', name: 'Santander de Quilichao (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '54680', name: 'Santiago (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '86760', name: 'Santiago (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '70820', name: 'Santiago de Tolú (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '05690', name: 'Santo Domingo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '08685', name: 'Santo Tomás (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '66687', name: 'Santuario (RIS)', regionCode: '66', countryCode: 'CO',
  },
  {
    code: '52720', name: 'Sapuyes (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '81736', name: 'Saravena (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '54720', name: 'Sardinata (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '25718', name: 'Sasaima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15720', name: 'Sativanorte (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15723', name: 'Sativasur (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05736', name: 'Segovia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25736', name: 'Sesquilé (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '76736', name: 'Sevilla (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15740', name: 'Siachoque (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25740', name: 'Sibaté (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '86749', name: 'Sibundoy (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '54743', name: 'Silos (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '25743', name: 'Silvania (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '19743', name: 'Silvia (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68745', name: 'Simacota (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25745', name: 'Simijaca (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '13744', name: 'Simití (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '70001', name: 'Sincelejo (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '27745', name: 'Sipí (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '47745', name: 'Sitionuevo (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '25754', name: 'Soacha (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15753', name: 'Soatá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15757', name: 'Socha (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '68755', name: 'Socorro (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15755', name: 'Socotá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15759', name: 'Sogamoso (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '18756', name: 'Solano (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '08758', name: 'Soledad (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '18785', name: 'Solita (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '15761', name: 'Somondoco (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05756', name: 'Sonson (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05761', name: 'Sopetrán (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '13760', name: 'Soplaviento (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '25758', name: 'Sopó (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15762', name: 'Sora (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15764', name: 'Soracá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15763', name: 'Sotaquirá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19760', name: 'Sotara (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68770', name: 'Suaita (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '08770', name: 'Suan (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '19780', name: 'Suárez (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '73770', name: 'Suárez (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '41770', name: 'Suaza (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25769', name: 'Subachoque (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '19785', name: 'Sucre (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '68773', name: 'Sucre (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '70771', name: 'Sucre (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '25772', name: 'Suesca (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25777', name: 'Supatá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '17777', name: 'Supía (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '68780', name: 'Suratá (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25779', name: 'Susa (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15774', name: 'Susacón (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15776', name: 'Sutamarchán (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25781', name: 'Sutatausa (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15778', name: 'Sutatenza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25785', name: 'Tabio (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27787', name: 'Tadó (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '13780', name: 'Talaigua Nuevo (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '20787', name: 'Tamalameque (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '85400', name: 'Támara (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '81794', name: 'Tame (ARA)', regionCode: '81', countryCode: 'CO',
  },
  {
    code: '05789', name: 'Támesis (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '52786', name: 'Taminango (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '52788', name: 'Tangua (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '97666', name: 'Taraira (VAU)', regionCode: '97', countryCode: 'CO',
  },
  {
    code: '91798', name: 'Tarapacá (AMA)', regionCode: '91', countryCode: 'CO',
  },
  {
    code: '05790', name: 'Tarazá (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '41791', name: 'Tarqui (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '05792', name: 'Tarso (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15790', name: 'Tasco (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '85410', name: 'Tauramena (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '25793', name: 'Tausa (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '41799', name: 'Tello (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25797', name: 'Tena (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '47798', name: 'Tenerife (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '25799', name: 'Tenjo (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15798', name: 'Tenza (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '54800', name: 'Teorama (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '41801', name: 'Teruel (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '41797', name: 'Tesalia (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25805', name: 'Tibacuy (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15804', name: 'Tibaná (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15806', name: 'Tibasosa (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25807', name: 'Tibirita (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54810', name: 'Tibú (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '23807', name: 'Tierralta (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '41807', name: 'Timaná (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '19807', name: 'Timbío (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '19809', name: 'Timbiquí (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '15808', name: 'Tinjacá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15810', name: 'Tipacoque (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '13810', name: 'Tiquisio (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05809', name: 'Titiribí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15814', name: 'Toca (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25815', name: 'Tocaima (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25817', name: 'Tocancipá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15816', name: 'Togüí (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '05819', name: 'Toledo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '54820', name: 'Toledo (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '70823', name: 'Tolú Viejo (SUC)', regionCode: '70', countryCode: 'CO',
  },
  {
    code: '68820', name: 'Tona (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '15820', name: 'Tópaga (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25823', name: 'Topaipí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '19821', name: 'Toribio (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '76823', name: 'Toro (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15822', name: 'Tota (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '19824', name: 'Totoró (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '85430', name: 'Trinidad (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '76828', name: 'Trujillo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '08832', name: 'Tubará (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '23815', name: 'Tuchín (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '76834', name: 'Tuluá (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15001', name: 'Tunja (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15832', name: 'Tununguá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '52838', name: 'Túquerres (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '13836', name: 'Turbaco (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '13838', name: 'Turbaná (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '05837', name: 'Turbo (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '15835', name: 'Turmequé (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15837', name: 'Tuta (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '15839', name: 'Tutazá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25839', name: 'Ubalá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25841', name: 'Ubaque (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '76845', name: 'Ulloa (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15842', name: 'Umbita (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25845', name: 'Une (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '27800', name: 'Unguía (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '27810', name: 'Unión Panamericana (CHO)', regionCode: '27', countryCode: 'CO',
  },
  {
    code: '05842', name: 'Uramita (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '50370', name: 'Uribe (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '44847', name: 'Uribia (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '05847', name: 'Urrao (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '44855', name: 'Urumita (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '08849', name: 'Usiacurí (ATL)', regionCode: '08', countryCode: 'CO',
  },
  {
    code: '25851', name: 'Útica (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '05854', name: 'Valdivia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '23855', name: 'Valencia (COR)', regionCode: '23', countryCode: 'CO',
  },
  {
    code: '68855', name: 'Valle de San José (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '73854', name: 'Valle de San Juan (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '86865', name: 'Valle del Guamuez (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '20001', name: 'Valledupar (CES)', regionCode: '20', countryCode: 'CO',
  },
  {
    code: '05856', name: 'Valparaíso (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '18860', name: 'Valparaíso (CAQ)', regionCode: '18', countryCode: 'CO',
  },
  {
    code: '05858', name: 'Vegachí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '68861', name: 'Vélez (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '73861', name: 'Venadillo (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '05861', name: 'Venecia (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '25506', name: 'Venecia (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15861', name: 'Ventaquemada (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25862', name: 'Vergara (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '76863', name: 'Versalles (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '68867', name: 'Vetas (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25867', name: 'Vianí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '17867', name: 'Victoria (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '05873', name: 'Vigía del Fuerte (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '76869', name: 'Vijes (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '54871', name: 'Villa Caro (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '15407', name: 'Villa de Leyva (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25843', name: 'Villa de San Diego de Ubate (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '54874', name: 'Villa del Rosario (NSA)', regionCode: '54', countryCode: 'CO',
  },
  {
    code: '19845', name: 'Villa Rica (CAU)', regionCode: '19', countryCode: 'CO',
  },
  {
    code: '86885', name: 'Villagarzón (PUT)', regionCode: '86', countryCode: 'CO',
  },
  {
    code: '25871', name: 'Villagómez (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '73870', name: 'Villahermosa (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '17873', name: 'Villamaría (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '13873', name: 'Villanueva (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '85440', name: 'Villanueva (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '44874', name: 'Villanueva (LAG)', regionCode: '44', countryCode: 'CO',
  },
  {
    code: '68872', name: 'Villanueva (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '25873', name: 'Villapinzón (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '73873', name: 'Villarrica (TOL)', regionCode: '73', countryCode: 'CO',
  },
  {
    code: '50001', name: 'Villavicencio (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '41872', name: 'Villavieja (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '25875', name: 'Villeta (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25878', name: 'Viotá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '15879', name: 'Viracachá (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '50711', name: 'Vistahermosa (MET)', regionCode: '50', countryCode: 'CO',
  },
  {
    code: '17877', name: 'Viterbo (CAL)', regionCode: '17', countryCode: 'CO',
  },
  {
    code: '25885', name: 'Yacopí (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '52885', name: 'Yacuanquer (NAR)', regionCode: '52', countryCode: 'CO',
  },
  {
    code: '41885', name: 'Yaguará (HUI)', regionCode: '41', countryCode: 'CO',
  },
  {
    code: '05885', name: 'Yalí (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05887', name: 'Yarumal (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05890', name: 'Yolombó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '05893', name: 'Yondó (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '85001', name: 'Yopal (CAS)', regionCode: '85', countryCode: 'CO',
  },
  {
    code: '76890', name: 'Yotoco (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '76892', name: 'Yumbo (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '13894', name: 'Zambrano (BOL)', regionCode: '13', countryCode: 'CO',
  },
  {
    code: '68895', name: 'Zapatoca (SAN)', regionCode: '68', countryCode: 'CO',
  },
  {
    code: '47960', name: 'Zapayán (MAG)', regionCode: '47', countryCode: 'CO',
  },
  {
    code: '05895', name: 'Zaragoza (ANT)', regionCode: '05', countryCode: 'CO',
  },
  {
    code: '76895', name: 'Zarzal (VAC)', regionCode: '76', countryCode: 'CO',
  },
  {
    code: '15897', name: 'Zetaquira (BOY)', regionCode: '15', countryCode: 'CO',
  },
  {
    code: '25898', name: 'Zipacón (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '25899', name: 'Zipaquirá (CUN)', regionCode: '25', countryCode: 'CO',
  },
  {
    code: '47980', name: 'Zona Bananera (MAG)', regionCode: '47', countryCode: 'CO',
  },
]
