import App from '@/App'
import '@/assets/fontawesome/js/all'
import { logout } from '@/assets/js/auth'
import { responseException } from '@/assets/js/nbxr'
import * as componentsButtons from '@/components/buttons'
import * as componentsSelect from '@/components/selects'
import * as directives from '@/directives'
import * as filters from '@/filters'
import { toKebabCase } from '@/helpers/string'
import { i18n } from '@/i18n'
import * as componentsLayout from '@/layouts'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueSweetalert2 from 'vue-sweetalert2'
import Vuex, { mapGetters } from 'vuex'

/* element...
-------------------------------------------------- */
Vue.use(ElementUI, {
  locale: elementEsLocale,
})
Vue.config.lang = 'es'

/* End of element...
-------------------------------------------------- */

Vue.use(VueResource)
Vue.use(Vuex)
Vue.use(VueSweetalert2)

// Vue.use(VueGtag, {
//   config: {
//     id: 'UA-100568924-5',
//     params: {
//       send_page_view: false
//     }
//   }
// })

/* Headers
-------------------------------------------------- */

Vue.http.options.root = process.env.VUE_APP_API_HUB

/* End of Headers
-------------------------------------------------- */

/* Components
-------------------------------------------------- */

Object.keys(directives).forEach(key => Vue.directive(toKebabCase(key), directives[key]))
Object.keys(componentsLayout).forEach(key => Vue.component(toKebabCase(key), componentsLayout[key]))
Object.keys(componentsSelect).forEach(key => Vue.component(toKebabCase(key), componentsSelect[key]))
Object.keys(componentsButtons).forEach(key => Vue.component(toKebabCase(key), componentsButtons[key]))

/* End of Components
-------------------------------------------------- */
/* Filters
-------------------------------------------------- */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

/* End of Filters
-------------------------------------------------- */
/* config
-------------------------------------------------- */
Vue.config.productionTip = false
Vue.config.performance = true

/* End of config
-------------------------------------------------- */
Vue.mixin({
  computed: mapGetters(['setting']),
})

/* http
-------------------------------------------------- */
Vue.http.interceptors.push(request => {
  request.emulateJSON = false
  if (localStorage.getItem('el_rey_mono_kung_fu')) {
    request.headers.set('Authorization', `Bearer ${localStorage.getItem('el_rey_mono_kung_fu')}`)
  }
})
Vue.http.interceptors.push(request => function (response) {
  if (response.status === 500) {
    ElementUI.Message.error(responseException(response))
  } if (response.status === 400) {
    ElementUI.Message.error(responseException(response))
  } else if (response.status === 401) {
    ElementUI.Message.error(i18n.tc('validateYourCredential'))
    logout()
    store.dispatch('setLogout')
  } else if (response.status === 404) {
    ElementUI.Message.error(i18n.tc('404'))
  } else if (response.status === 403 && response.body.includes('Token revocado')) {
    ElementUI.Message.error(i18n.tc('validateYourCredential'))
    logout()
    store.dispatch('setLogout')
  }
})

/* End of http
-------------------------------------------------- */

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  router,
  components: {
    App,
  },
  render: h => h(App),
  template: '<App/>',
  i18n,
})
