<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    :remote-method="onSearch"
    clearable
    filterable
    remote
    reserve-keyword
    @change="onChange"
  >
    <el-option
      v-for="item in data"
      :key="`${item.companyKey}-${item.serviceId}`"
      :value="item.companyKey"
      :label="`(${item.companyKey }) - ${item.companyName} - [${item.companyServiceStatus}]`"
    >
      <span style="float: left;overflow: hidden;text-overflow: ellipsis;max-width: 80%;">{{ `(${item.companyKey }) - ${item.companyName}` }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px; font-style:italic">{{ item.companyServiceStatus }}</span>
    </el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('companies', 'company', { attributeValue: 'companyKey' }),
  props: {
    serviceId: { required: true },
  },
  data() {
    return model()
  },
  watch: {
    value(newValue, oldValue) {
      this.setRemoteInitValue(`findByKey/${newValue}`)
    },
  },
  created() {
    this.setRemoteInitValue(`findByKey/${this.value}`)
  },
  methods: {
    onSearch(query) {
      const _this = this
      _this.data = []

      if (query !== '' && query.length > 3 && _this.serviceId) {
        _this.query.searchTerm = query
        _this.query.limit = 20

        _this.get(`${_this.serviceId}/findByService`)
      }
    },
  },
}
</script>
