<template>
  <el-select :value="value" placeholder="..." filterable clearable @change="onChange">
    <el-option v-for="item in options" :key="item.id" :label="item.currencyName" :value="item.id"></el-option>
  </el-select>
</template>
<script>

export default {
  props: {
    value: { type: String },
  },
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    const _this = this
    _this.$http.get('currencies').then(response => {
      _this.options = response.body
    }, response => {

    })
  },
  methods: {
    onChange(value) {
      const _this = this
      _this.$emit('input', value)
      _this.$emit('set-currency', value)
    },
  },
}
</script>
