<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    :remote-method="onSearch"
    filterable
    clearable
    remote
    @change="onChange"
  >
    <el-option
      v-for="item in data"
      :key="`${item.companyKey}-${item.serviceId}`"
      :value="item.companyKey"
      :label="`(${item.companyKey}) - ${item.companyName} - [${item.companyServiceStatus}]`"
    >
      <span style="float: left; overflow: hidden; text-overflow: ellipsis; max-width: 350px">{{
        `(${item.companyKey}) - ${item.companyName}`
      }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px; font-style: italic"
        >{{ item.companyServiceStatus }} ({{ item.serviceName }})</span
      >
      <br />
    </el-option>
  </el-select>
</template>

<script>
import select, { model } from './common'

export default {
  extends: select('companies', 'company', { attributeValue: 'companyKey' }),
  data() {
    return model()
  },
  watch: {
    value(newValue, oldValue) {
      const _this = this
      if (!_this.data.find((item) => item.companyKey === newValue) && newValue) {
        _this.setRemoteInitValue(`findByKey/${newValue}`)
      }
    }
  },
  methods: {
    onSearch(query) {
      const _this = this
      if (query !== '' && query.length > 3) {
        _this.query.searchTerm = query
        _this.query.limit = 20

        _this.get('findByName')
      } else {
        _this.data = []
      }
    }
  }
}
</script>
