<template>
  <el-select :value="value" placeholder="..." filterable clearable @change="onChange">
    <el-option v-for="item in options" :key="item.code" :label="item.name + ' - ' + item.code" :value="item.code"></el-option>
  </el-select>
</template>
<script>
import { currencies } from '../../assets/js/data/currencies'

export default {
  props: {
    value: { type: String },
    notEqualCompany: { type: Boolean, default: false },
  },
  data() {
    return {
      options: currencies,
    }
  },
  mounted() {
    const _this = this
    if (_this.notEqualCompany) {
      _this.options = currencies.filter(i => i.code !== _this.vCompany.currencyId)
      _this.onChange('USD')
    } else {
      _this.onChange('COP')
    }
  },
  methods: {
    onChange(value) {
      // console.log('cur--', value)
      const _this = this
      _this.$emit('input', value)
      _this.$emit('set-currency', value)
    },
  },
}
</script>
