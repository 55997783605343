<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :placeholder="label"
    clearable
    :disabled="disabled"
    :company-key="companyKey"
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :label="item.serviceName" :value="item.id"></el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('services', 'service'),
  props: {
    companyKey: { required: false },
    disabled: { required: false },
  },
  data() {
    return model()
  },
  watch: {
    value(newValue) {
      this.dispatchSetSelectedEntity()
    },
  },
  created() {
    const _this = this
    this.get('', {
      uri: _this.companyKey > 0 ? `companies/${_this.companyKey}/missingServices` : '',
    })
  },

  // mounted () {
  //   this.value = '1'
  // }
}
</script>
