<template>
  <el-select :value="value">
    <el-option label="Español" value="es"></el-option>
    <el-option label="English" value="en"></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
  mounted() {
    this.$emit('input', 'es')
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
    },
  },
}
</script>
