<template>
  <el-select
    :value="value"
    :placeholder="`${$tc('status')} / ${$tc('all', 1)}`"
    :clearable="clearable"
    @clear="onClear"
    @change="onSelect"
  >
    <el-option
      v-for="option in options"
      :key="option.key"
      :label="option.value"
      :value="option.key"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: { type: String },
    clearable: { type: Boolean, default: false },
  },
  data() {
    return {
      options: [
        { key: 'pending', value: 'Pendiente de carga de documentos' },
        { key: 'rejected', value: 'Documentos rechazados' },
        { key: 'approved', value: 'Documentos aceptados' },
        { key: 'process', value: 'En proceso' },
      ],
    }
  },
  methods: {
    onClear() {
      this.$emit('input', null)
      this.$emit('set-selected', {})
    },
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
