<template>
  <el-select :value="value" placeholder="Rol..." clearable :init="false" :disabled="disabled" @change="onChange">
    <el-option v-for="item in options" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
  </el-select>
</template>
<script>

export default {
  props: ['value', 'init', 'disabled'],
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
      this.$emit('set-role', e)
    },
    get() {
      const _this = this
      _this.$http.get('roles').then(response => {
        _this.options = response.body
        if (_this.init) {
          _this.$emit('input', _this.options[1].id)
          _this.$emit('set-role', _this.options[1].id)
        }
      }, response => {

      })
    },
  },
}
</script>
