<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      :placeholder="label"
      :clearable="clearable"
      @change="onChange"
    >
      <el-option
        v-for="item in data"
        :key="item.id"
        :value="item.id"
        :label="`${item.userFullName} - (${item.userEmail})`"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import select, { model } from './common'

export default {
  extends: select('users', 'vendor'),
  data() {
    return model()
  },
  created() {
    this.get('sellers')
  },
  methods: {
    onChange(value) {
      const _this = this
      _this.$emit(
        'set-selected',
        _this.data.find(e => e.id === value),
      )
      _this.$emit('change', value)
      _this.$emit('input', value)
    },
  },
  computed: {
    ...mapGetters(['setting']),
  },
}
</script>
