import store from '@/store'

function validateRole(binding, el, roleId) {
  el.style.visibility = (binding.value.includes(roleId)) ? '' : 'hidden'
  el.style.display = (binding.value.includes(roleId)) ? '' : 'none'
}

function update(
  el,
  binding,
) {
  validateRole(binding, el, +store.state.setting.roleId)
  store.watch(
    _ => store.state.setting,
    newValue => {
      validateRole(binding, el, +newValue.roleId)
    },
    {
      deep: true,
    },
  )
}

export default {
  bind: update,
  update,
}
