import { Loading } from 'element-ui'

const loadingsInstance = []

const displayLoading = (binding, el) => {
  const loadingInstance = loadingsInstance.find(item => item.el === el)
  if (binding.value && !loadingInstance) {
    loadingsInstance.push({
      el,
      loading: Loading.service({
        fullscreen: false,
        target: el,
      }),
    })
  } else if (!binding.value && loadingInstance) {
    loadingInstance.loading.close()
    loadingsInstance.splice(loadingsInstance.indexOf(loadingInstance), 1)
  }
}

export default {
  bind(el, binding) {
    displayLoading(binding, el)
  },
  update(el, binding) {
    displayLoading(binding, el)
  },
}
