<template>
  <div v-loading="loading">
    <el-select :value="value" :placeholder="label" @change="onChange">
      <el-option :label="$tc('yearly')" value="Yearly">
      </el-option>
      <el-option :label="$tc('monthly')" value="Monthly">
      </el-option>
    </el-select>
  </div>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('', 'recurrence'),
  data() {
    return model()
  },
}
</script>
