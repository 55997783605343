<template>
  <div class="credit-cards">
    <input
      type="radio"
      name="creditCard"
      value="CR_VS"
      data-icon=""
      :checked="value === 'CR_VS'"
      @click="onChange('CR_VS')"
    />
    <input
      type="radio"
      name="creditCard"
      value="RM_MC"
      data-icon=""
      :checked="value === 'RM_MC'"
      @click="onChange('RM_MC')"
    />
    <input
      type="radio"
      name="creditCard"
      value="CR_AM"
      data-icon=""
      :checked="value === 'CR_AM'"
      @click="onChange('CR_AM')"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
    },
  },
}
</script>
<style lang="scss" scoped>
@import url("~@/assets/fontawesome/css/brands.min.css");

.credit-cards {
  margin: 2px;
  ::v-deep input[name="creditCard"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: none;

    &:after {
      font-family: "Font Awesome 5 Brands";
      content: attr(data-icon);
      display: inline-block;
      text-align: center;
      font-size: 45px;
      padding: 0 5px;
      border-radius: 5px;
      color: rgba(0, 0, 0, 0.4);
      transition: box-shadow 1s, color 1s;
    }

    &:checked {
      &:after {
        box-shadow: 0px 0px 14px var(--genericGradient1);
        color: var(--genericGradient2);
      }
    }
  }
}
</style>
