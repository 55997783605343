<template>
  <el-select
    v-cus-loading="loading"
    :value="value"
    :disabled="disabled"
    :placeholder="label"
    :remote-method="onSearch"
    filterable
    clearable
    remote
    @change="onChange"
  >
    <el-option v-for="item in data" :key="item.id" :value="item.id" :label="item.planName"></el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('plans', 'plan'),
  props: {
    serviceId: { required: true },
  },
  data() {
    return model()
  },
  watch: {
    value(newValue, oldValue) {
      const _this = this
      _this.setRemoteInitValue(`${newValue}`)
    },
  },
  methods: {
    onSearch(query) {
      const _this = this
      if (query !== '' && query.length > 3) {
        _this.query.limit = 20
        _this.get('search', {
          queryStringParams: {
            planName: query,
            serviceId: _this.serviceId,
          },
        })
      } else {
        _this.data = []
      }
    },
  },
}
</script>
