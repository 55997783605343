<template>
  <el-container style="height: 100%">
    <el-header style="text-align: right">
      <el-menu mode="horizontal">
        <el-menu-item index="1">
          <img
            class="header-logo"
            width="100"
            height="45"
            src="@/assets/img/svg/logo-aliaddo.svg"
            alt="Panel ALIADDO"
          />
        </el-menu-item>
        <el-menu-item index="2-1">
          <el-button
            type="text"
            size="medium"
            @click="gotoPage('my-account-index')"
          >
            {{ userFullName }}
          </el-button>
        </el-menu-item>
        <el-menu-item index="3">
          <el-button type="text" size="medium" @click="gotoPage('logout')">
            Salir
          </el-button>
        </el-menu-item>
        <!-- <el-menu-item index="4"></el-menu-item> -->
      </el-menu>
    </el-header>
    <el-container>
      <el-aside style="background-color: rgb(67, 74, 80)" width="240px">
        <el-menu
          :router="true"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-submenu v-cus-validate-role="[1, 2, 3, 4]" index="1">
            <template slot="title">
              <i class="fad fa-building"></i>
              <span>Empresas</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1" route="/companies">
                {{ $tc('read') }}
              </el-menu-item>
              <el-menu-item
                v-cus-validate-role="[1, 2]"
                index="1-2"
                route="/companies/create"
              >
                {{ $tc('create') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-cus-validate-role="[1, 2]" index="2">
            <template slot="title">
              <i class="fad fa-cubes"></i>
              <span>Planes</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="2-1" route="/plans">
                {{ $tc('read') }}
              </el-menu-item>
              <el-menu-item index="2-2" route="/plans/create">
                {{ $tc('create') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-cus-validate-role="[1, 2]" index="3">
            <template slot="title">
              <i class="fad fa-thumbtack"></i>
              <span>{{ $tc('feature', 2) }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="3-1" route="/features">
                {{ $tc('read') }}
              </el-menu-item>
              <el-menu-item index="3-2" route="/features/create">
                {{ $tc('create') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-cus-validate-role="[1, 2]" index="4">
            <template slot="title">
              <i class="fad fa-puzzle-piece"></i>
              <span>{{ $tc('addOn') }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="4-1" route="/add-ons">
                {{ $tc('read') }}
              </el-menu-item>
              <el-menu-item index="4-2" route="/add-ons/create">
                {{ $tc('create') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-cus-validate-role="[1, 2]" index="5" route="/tenants">
            <i class="fad fa-abacus"></i>
            <span>Tenants</span>
          </el-menu-item>
          <el-submenu v-cus-validate-role="[1, 2, 3, 4]" index="7">
            <template slot="title">
              <i class="fad fa-users"></i>
              <span>Usuarios</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="7-1" route="/users">
                <span>{{ $tc('read') }}</span>
              </el-menu-item>
              <el-menu-item
                v-cus-validate-role="[1, 2]"
                index="7-2"
                route="/users/create"
              >
                <span>{{ $tc('create') }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-cus-validate-role="[1, 2, 3, 4]" index="9">
            <template slot="title">
              <i class="fad fa-file-invoice"></i>
              <span>Comprobantes</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="9-1" route="/vouchers">
                <span>{{ $tc('read') }}</span>
              </el-menu-item>
              <el-menu-item
                v-cus-validate-role="[1, 2, 4]"
                index="9-2"
                route="/vouchers/create"
              >
                <span>{{ $tc('create') }}</span>
              </el-menu-item>
              <!-- <el-menu-item index="9-2" route="/newsletters/create">{{ $tc('create') }}</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu v-cus-validate-role="[1, 2]" index="8">
            <template slot="title">
              <i class="fad fa-envelope-open-text"></i>
              <span>Boletin de noticias</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="8-1" route="/newsletters">
                <span>{{ $tc('read') }}</span>
              </el-menu-item>
              <el-menu-item index="8-2" route="/newsletters/create">
                {{ $tc('create') }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view> </router-view>
        </el-main>
        <el-footer style="height: 32px"> ALIADDO 2020 </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { ACCESS_TOKEN_KEY } from '../assets/js/auth'
import router from '../router'

export default {
  name: 'PrivateLayout',
  computed: {
    ...mapGetters(['setting']),
    userFullName: () => JSON.parse(localStorage.getItem('masters')).fullName,
  },
  methods: {
    gotoPage(routeName) {
      if (routeName === 'logout') {
        localStorage.removeItem('masters')
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(`${ACCESS_TOKEN_KEY}_expires_in`)
        this.$store.commit('setLogout')
        router.push({ name: 'Login' })
      } else {
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>
