<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      :placeholder="label"
      @change="onChange"
    >
      <el-option
        :label="$tc('recurrence')"
        value="Periodicidad"
      >
      </el-option>
      <el-option
        :label="$tc('opening')"
        value="Apertura"
      >
      </el-option>
      <el-option
        :label="$tc('changePlan')"
        value="CambioPlan"
      >
      </el-option>
      <el-option
        :label="$tc('incrementalPlan')"
        value="AdicionarCaracteristica"
      >
      </el-option>
      <el-option
        :label="$tc('package', 0)"
        value="Paquete"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('', 'recurrence'),
  data() {
    return model()
  },
}
</script>
