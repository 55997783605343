<template>
  <el-select
    :value="value"
    :clearable="clearable"
    :placeholder="`${$tc('select')} ${$tc('year', 2)}`"
    @change="onSelect($event)"
    @clear="onClear()"
  >
    <el-option v-for="option in options" :key="option" :value="option" :label="option">
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    clearable: { type: Boolean, default: false },
    value: { default: new Date().getFullYear() },
  },
  computed: {
    options: {
      get(_this) {
        const options = []
        for (let index = 2019; index < new Date().getFullYear() + 1; index++) {
          options.push(index)
        }

        return options
      },
    },
  },
  methods: {
    onClear() {
      this.$emit('input', '')
      this.$emit('set-selected', {})
    },
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
