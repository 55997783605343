<template>
  <el-select
    :value="value"
    :clearable="clearable"
    :placeholder="$tc('typeForSearchCity')"
    :remote-method="onLocalSearch"
    filterable
    remote
    reserve-keyword
    @change="onChange"
  >
    <el-option v-for="(item, index) in data" :key="index" :value="item.code" :label="item.name">
      <span style="float: left">{{ item.name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.code }}</span>
    </el-option>
  </el-select>
</template>
<script>
import select, { model } from './common'
import data from '@/assets/js/data/CO/cities'
import { latinize } from '@/helpers'

export default {
  extends: select('', 'city', { attributeLabel: 'name', attributeValue: 'code', data }),
  props: {
    region: {
      type: String,
      required: true,
    },
  },
  data() {
    return model()
  },
  watch: {
    value(newValue) {
      this.setLocalInitValue(newValue)
    },
  },
  mounted() {
    this.setLocalInitValue(this.value)
  },
  methods: {
    onLocalSearch(query) {
      const _this = this
      if (query !== '' && query.length > 3) {
        _this.data = data.filter(item => latinize(item.name).includes(latinize(query)) && item.regionCode === _this.region)
      } else {
        _this.data = []
      }
    },
  },
}
</script>
