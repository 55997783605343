<template>
  <div>
    <el-radio :value="value" label="13"> CC </el-radio>
    <el-radio :value="value" label="31"> NIT </el-radio>
    <!-- <el-radio
      v-for="item in options"
      :key="item.id"
      :value="value"
      :name="name"
      :label="item.id"
    >
      {{ item.identificationTypeCode }}
    </el-radio> -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      test: '',
      options: [],
    }
  },
  mounted() {
    const _this = this
    _this.$http.get('identificationTypes').then(response => {
      if (response.body.length > 0) {
        _this.options = response.body.filter(item =>
          ['31', '13'].includes(item.id),
        )
      } else {
        _this.options = []
      }
      _this.$emit('input', '31')
    })

    // const _this = this
    // db.identificationType.toArray().then(data => {
    //   this.options = data
    // })
  },
  methods: {
    onChange(event) {
      const _this = this
      _this.$emit('input', event)
      if (event) {
        _this.$emit(
          'set-identification-type-code',
          _this.options.find(k => k.id === event).identificationTypeCode,
        )
        _this.$emit(
          'set-identification-type-name',
          _this.options.find(k => k.id === event).identificationTypeName,
        )
      } else {
        _this.$emit('set-identification-type-code', '')
        _this.$emit('set-identification-type-name', '')
      }
    },
  },
}
</script>
