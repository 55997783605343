<template>
  <el-select
    :value="value"
    :placeholder="`${$tc('month')} / ${$tc('all', 1)}`"
    :clearable="clearable"
    @clear="onClear"
    @change="onSelect"
  >
    <el-option v-for="option in options" :key="option.key" :label="option.value" :value="option.key">
    </el-option>
  </el-select>
</template>
<script>
import { Info } from 'luxon'
import { getMonthName, toPascalCase } from '@/helpers'

export default {
  props: {
    value: { type: Number },
    clearable: { type: Boolean, default: false },
  },
  computed: {
    options: {
      get(_this) {
        return Info.months('numeric', { locale: 'es' }).map(item => ({
          key: +item,
          value: toPascalCase(getMonthName(item)),
        }))
      },
    },
  },
  methods: {
    onClear() {
      this.$emit('input', null)
      this.$emit('set-selected', {})
    },
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
