import { DateTime } from 'luxon'

const monthSpanish = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

export function monthEs(month) {
  return monthSpanish[month]
}

export function calculatePage(array, pageSize, pageNumber) {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}
export function responseException(response) {
  let message = ''
  if (response) {
    if (response && response.body && response.body.exceptionMessage) {
      if (response.body.exceptionMessage.indexOf('1062') !== -1) {
        message = `Ya existe un registro con los mismos datos => ${response.body.exceptionMessage}`
      } else {
        message = response.body.exceptionMessage
      }
    } else if (response && response.body && (response.body.message || response.body.Message)) {
      message = response.body.message || response.body.Message
    } else if (response && response.body && response.body.error) {
      message = response.body.error
    } else if (response && response.bodyText) {
      message = response.bodyText
    } else if (response && response.message) {
      message = response.message
    } else if (response && response.body) {
      message = response.body
    } else if (response && response.responseText && response.responseText.indexOf('html') === -1) {
      message = response.responseText
    } else if (response.statusText) {
      message = response.statusText
    } else {
      message = 'Ha ocurrido un error un inesperado, el equipo de soporte ya fue notificado!'
    }
  } else {
    message = 'Ha ocurrido un error un inesperado, el equipo de soporte ya fue notificado!'
  }

  return message
}

export function calcularDv(nit) {
  // console.log(nit.length)
  if (isNaN(nit)) {
    // window.alert('Ingrese un número sin puntos, ni comas, ni guiones, ni espacios')
    return ''
  }

  // if (nit.length === 9) {
  let arreglo
  let dv
  arreglo = new Array(16)
  arreglo[1] = 3
  arreglo[2] = 7
  arreglo[3] = 13
  arreglo[4] = 17
  arreglo[5] = 19
  arreglo[6] = 23
  arreglo[7] = 29
  arreglo[8] = 37
  arreglo[9] = 41
  arreglo[10] = 43
  arreglo[11] = 47
  arreglo[12] = 53
  arreglo[13] = 59
  arreglo[14] = 67
  arreglo[15] = 71

  let x = 0
  let y = 0
  for (let i = 0; i < nit.length; i++) {
    y = nit.substr(i, 1)
    x += y * arreglo[nit.length - i]
  }
  y = x % 11
  if (y > 1) {
    dv = 11 - y
  } else {
    dv = y
  }

  return dv
}

export function now() {
  return DateTime.local()
}
