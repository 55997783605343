<template>
  <el-select
    :value="value"
    :placeholder="`${$tc('select')} ${$tc('documentType')}`"
    value-key="key"
    @change="onChange"
  >
    <el-option
      v-for="option in options"
      :key="option.key"
      :label="`(${option.key}) ${option.value}`"
      :value="option.key"
    ></el-option>
  </el-select>
</template>
<script>
import options from '@/assets/js/data/place_to_pay/document_types'

export default {
  props: {
    value: {
      default: 'CC',
    },
  },
  data() {
    return {
      options,
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('set-option', this.options.find(i => i.key === value))
    },
  },
}
</script>
