<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      :clearable="clearable"
      :placeholder="label"
      :remote-method="onSearch"
      filterable
      remote
      reserve-keyword
      @change="onChange"
    >
      <el-option v-for="item in data" :key="item.personId" :value="item.personId" :label="`${item.personName} ${item.personFullNumberIdentification}`">
      </el-option>
    </el-select>
  </div>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('people', 'person', { attributeValue: 'personId' }),
  props: {
    type: { default: 'seller', required: false, type: String },
  },
  data() {
    return model()
  },
  watch: {
    value(newValue, oldValue) {
      this.setInitValue(newValue)
    },
  },
  created() {
    const _this = this
    this.setInitValue(_this.value)
  },
  methods: {
    setInitValue(value) {
      const _this = this
      if (!_this.data.find(item => item.personId === value) && value) {
        _this.setRemoteInitValue(value)
      }
    },
    onSearch(query) {
      const _this = this
      if (query !== '' && query.length > 3) {
        _this.query.searchTerm = query

        if (_this.type === 'seller') {
          _this.get('sellers')
        } else if (_this.type === 'customer') {
          _this.get('customers')
        }
      } else {
        _this.data = []
      }
    },
  },
}
</script>
