<template>
  <el-select
    :value="value"
    placeholder="Zona horaria..."
    filterable
    clearable
    @change="onChange"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>
<script>
import { timeZones } from '../../assets/js/data/timeZones'

export default {
  props: {
    value: { type: String, default: 'America/Bogota' },
  },
  data() {
    return {
      options: timeZones,
    }
  },
  mounted() {
    this.$emit('input', 'America/Bogota')
  },
  methods: {
    onChange(e) {
      // console.log('Onchange timeZone', e)
      this.$emit('input', e)
    },
  },
}
</script>
