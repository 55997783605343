<template>
  <el-container>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'PublicLayout',
}
</script>
<style>
@import url("../assets/css/custom.css");
</style>
<style lang="scss">
@import "./../assets/scss/index.scss";
</style>
