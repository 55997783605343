<template>
  <el-select :value="value" :placeholder="$tc('paymentMethod')" @change="onChange">
    <el-option value="CR" label="Tarjeta de crédito">
      Tarjeta de crédito
    </el-option>
    <!-- <el-option value="CR_VS" label="Tarjeta de crédito (VISA)">Tarjeta de crédito (VISA)</el-option> -->
    <!-- <el-option value="RM_MC" label="Tarjeta de crédito (MasterCard)">Tarjeta de crédito (MasterCard)</el-option> -->
    <!-- <el-option value="CR_DN" label="Tarjeta de crédito (Diners)">Tarjeta de crédito (Diners)</el-option> -->
    <!-- <el-option value="CR_AM" label="Tarjeta de crédito (American Express)">Tarjeta de crédito (American Express)</el-option> -->
    <el-option value="_PSE_" label="Cuentas débito ahorro y corriente (PSE)">
      Cuentas débito ahorro y corriente (PSE)
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: { type: String }
  },
  mounted() {
    this.$emit('input', 'CR')
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
    }
  }
}
</script>
