<template>
  <div v-loading="loading">
    <el-select :value="value" :placeholder="$tc('entity.user', 1)" @change="onChange">
      <el-option v-for="item in data" :key="item.id" :label="item.userEmail + ' (' + item.userFirstName + ')'" :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>
<script>

import select, { model } from './common'

export default {
  extends: select('users'),
  data() {
    return model()
  },
  created() {
    this.get('aliaddo')
  },
}
</script>
