<template>
  <component :is="layout"></component>
</template>
<script>
const defaultLayout = 'public'
export default {
  name: 'App',
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`
    },
  },
}
</script>
