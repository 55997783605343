<template>
  <el-select
    :value="value"
    placeholder="Plan..."
    :service-id="serviceId"
    value-key="id"
    clearable
    :init-blank="false"
    @change="onChange"
  >
    <el-option v-for="item in options" :key="item.id" :label="item.planName + ' (' + item.planMode + ')'" :value="item"></el-option>
  </el-select>
</template>
<script>

export default {
  props: ['value', 'serviceId', 'initBlank'],
  data() {
    return {
      options: [],
    }
  },
  watch: {
    serviceId() {
      this.get()
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    onChange(value) {
      const _this = this
      _this.$emit('input', value)
      _this.$emit('set-plan', value)
    },
    get() {
      const _this = this
      _this.options = []
      if (_this.initBlank) {
        _this.$emit('input', '')
        _this.$emit('set-plan', '')
      }
      if (_this.serviceId) {
        _this.$http.get(`plans/findByService/${_this.serviceId}`).then(response => {
          _this.options = response.body.filter(p => p.planMode !== 'paquetePeriodicidad')
        }, response => {

        })
      }
    },
  },
}
</script>
